import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Chart from '../../../components/Chart';
import PieChart from '../../../components/PieChart';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';
import moment from 'moment';
import useApi from '../../../api/useApi';
import {
  BUDGET,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  RECENT_TRANSACTIONS,
  EXPENSES,
  EXPENSE_REQUEST_LIST,
  DELETE_CATEGORY,
  ASK_FOR_JUSTIFICATION,
} from '../../../api/urls/finance';
import DropDown from '../../../components/UI/DropDown';
import ExpenseJustification from '../../../components/Owner/Budget/ExpenseJustification';
import ExpenseRequest from '../../../components/Owner/Budget/ExpenseRequest';
import RequestDetail from '../../../components/Owner/Budget/RequestDetail';
import JustificationDetail from '../../../components/Owner/Budget/JustificationDetail';
import { MONTHS } from '../../../constants';

const excelHeaders = [
  { label: 'Amount', key: 'amount' },
  { label: 'Transaction Detail', key: 'transactionDetail' },
  { label: 'Date', key: 'date' },
  { label: 'Time', key: 'time' },
];

const Budget = ({ role }) => {
  const location = useLocation();

  const [excelData, setExcelData] = useState([]);
  const [showBudget, setShowBudget] = useState(false);
  const [month, setMonth] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const [modify, setModify] = useState(false);
  const [view, setView] = useState('List View');
  const [selectedCategory, selectCategory] = useState();
  const [selectedRequestDetail, selectRequestDetail] = useState(null);
  const [selectedJustificationDetail, selectJustificationDetail] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [expensesRequests, setExpensesRequests] = useState([]);
  const [monthlyBudget, setMonthlyBudget] = useState([]);
  const [categoryBudget, setCategoryBudget] = useState();
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [newAmount, setNewAmount] = useState(0);
  const [category, setCategory] = useState('');
  const [amount, setAmount] = useState(0);
  const [selectedTransaction, selectTransaction] = useState(false);

  const [modifyState, modifyCategory] = useApi();
  const [{ isLoading, isError, data, status }, createCategory] = useApi();
  const [budget, getBudget] = useApi();
  const [budgetCategories, getBudgetCategories] = useApi();
  const [recentTransactionsRes, getRecentTransactions] = useApi();
  const [expensesRes, getExpenses] = useApi();
  const [expenseRequestsRes, getExpenseRequests] = useApi();
  const [deleteCategoryRes, deleteCategory] = useApi();
  const [askForJustificationRes, askForJustification] = useApi();

  const months = [
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'January',
    'February',
    'March',
  ];

  useEffect(() => {
    getBudget(BUDGET(''), 'GET');
    getRecentTransactions(RECENT_TRANSACTIONS, 'GET');
    getExpenses(EXPENSES, 'GET');
    getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
  }, []);

  useEffect(() => {
    if (location.search.includes('pending')) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  useEffect(() => {
    if (!expensesRes.isLoading && !expensesRes.isError && expensesRes.data) {
      setExpenses(expensesRes.data);
    }
  }, [expensesRes.isLoading, expensesRes.isError, expensesRes.data]);

  useEffect(() => {
    if (!expenseRequestsRes.isLoading && !expenseRequestsRes.isError && expenseRequestsRes.data) {
      setExpensesRequests(expenseRequestsRes.data);
    }
  }, [expenseRequestsRes.isLoading, expenseRequestsRes.isError, expenseRequestsRes.data]);

  const handleAdd = (e) => {
    e.preventDefault();

    const body = {
      month: {
        name: month,
        min: moment(new Date(new Date().getFullYear(), new Date(Date.parse(month + ' 1, 2012')).getMonth(), 1)).format(
          'YYYY-MM-DD'
        ),
        max: moment(
          new Date(new Date().getFullYear(), new Date(Date.parse(month + ' 1, 2012')).getMonth() + 1, 0)
        ).format('YYYY-MM-DD'),
      },
      amount: amount,
      category: category,
    };
    createCategory(CREATE_CATEGORY, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      getBudgetCategories(`${BUDGET(month)}`, 'GET');
      setAddCategory(false);
      setAmount(0);
      setCategory('');
      // setShowBudget(false);
      getBudget(BUDGET(''), 'GET');
      getExpenses(EXPENSES, 'GET');
      getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
      alert('Success');
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  useEffect(() => {
    if (!budget.isLoading && !budget.isError && budget.data) {
      const _list = budget?.data?.response?.data;
      if (Array.isArray(_list)) {
        // const months = [...new Set(_list.map((x) => x.month))];

        let arr = [];
        for (let i = 0; i < months.length; i++) {
          let obj = {};
          obj.month = months[i];
          obj.amount = _list?.reduce((acc, cur) => {
            if (cur.month === months[i]) {
              acc = acc + cur.amount;
            }
            return acc;
          }, 0);
          obj.expense = _list?.reduce((acc, cur) => {
            if (cur.month === months[i]) {
              acc = acc + cur.expense;
            }
            return acc;
          }, 0);
          obj.category = _list.filter((x) => x.month === months[i]);
          arr.push(obj);
        }

        setMonthlyBudget(arr);

        // if (categoryBudget) {
        //   setCategoryBudget(arr.find((x) => x.month === month).category);
        // }
      }
    }
  }, [budget]);

  useEffect(() => {
    if (month) {
      getBudgetCategories(`${BUDGET(month)}`, 'GET');
    }
  }, [month]);

  useEffect(() => {
    if (!budgetCategories.isLoading && !budgetCategories.isError && budgetCategories.data) {
      setCategoryBudget(budgetCategories.data.data);
    }
  }, [budgetCategories]);

  useEffect(() => {
    if (!recentTransactionsRes.isLoading && !recentTransactionsRes.isError && recentTransactionsRes.data) {
      setRecentTransactions(recentTransactionsRes.data);

      if (recentTransactionsRes.data && Array.isArray(recentTransactionsRes.data)) {
        const data = recentTransactionsRes.data;
        let x = data.map((x) => {
          return {
            amount: x.total_fee ? x.total_fee : Array.isArray(x.amount) ? x.amount[0] : x.amount,
            transactionDetail: getDetail(x),
            date: moment(x.date).format('ll'),
            time: moment(x.date).format('hh:mm'),
          };
        });

        if (Array.isArray(x)) setExcelData(x);
      }
    }
  }, [recentTransactionsRes]);

  const handleModify = (e) => {
    e.preventDefault();
    // const body = {
    //   amount: newAmount,
    // };
    const body = {
      month: {
        name: month,
        min: moment(new Date(new Date().getFullYear(), new Date(Date.parse(month + ' 1, 2012')).getMonth(), 1)).format(
          'YYYY-MM-DD'
        ),
        max: moment(
          new Date(new Date().getFullYear(), new Date(Date.parse(month + ' 1, 2012')).getMonth() + 1, 0)
        ).format('YYYY-MM-DD'),
      },
      amount: newAmount,
    };
    modifyCategory(UPDATE_CATEGORY(selectedCategory.category._id), 'PUT', body);
  };

  useEffect(() => {
    if (!modifyState.isLoading && !modifyState.isError && (modifyState.status === 200 || modifyState.status === 201)) {
      getBudgetCategories(`${BUDGET(month)}`, 'GET');
      setModify(false);
      getBudget(BUDGET(''), 'GET');
      alert('Success!');
    }

    if (!modifyState.isLoading && modifyState.isError) {
      alert('Failed!');
    }
  }, [modifyState]);

  const getDetail = (x) => {
    if (x?.category) {
      return x?.name;
    } else if (x?.studentId) {
      return 'Fee';
    }
  };

  const getAmountRounded = (amount) => {
    var val = Math.abs(amount);
    // if (val >= 10000000) {
    //   val = val / 10000000 + ' Crore';
    // } else if (val >= 100000) {
    //   val = val / 100000 + ' Lakhs';
    // } else if (val >= 1000) {
    //   val = val / 1000 + ' Thousand';
    // }
    return '\u20B9' + val;
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete?')) deleteCategory(DELETE_CATEGORY(id, month), 'DELETE');
  };

  useEffect(() => {
    if (!deleteCategoryRes.isLoading && !deleteCategoryRes.isError && deleteCategoryRes.status === 201) {
      getBudgetCategories(`${BUDGET(month)}`, 'GET');
      getBudget(BUDGET(''), 'GET');
      alert('Category deleted successfully');
    }

    if (!deleteCategoryRes.isLoading && deleteCategoryRes.isError) {
      alert('Failed to delete');
    }
  }, [deleteCategoryRes.isLoading, deleteCategoryRes.isError, deleteCategoryRes.data]);

  function printDiv() {
    window.print();
  }

  const handleAskFOrJustification = (id) => {
    askForJustification(ASK_FOR_JUSTIFICATION(id), 'PUT');
  };

  useEffect(() => {
    if (!askForJustificationRes.isLoading && !askForJustificationRes.isError && askForJustificationRes.status == 202) {
      getExpenses(EXPENSES, 'GET');
      alert('SUCCESS');
      selectTransaction(null);
    }
    if (!askForJustificationRes.isLoading && askForJustificationRes.isError) {
      alert('Error');
    }
  }, [askForJustificationRes]);

  return (
    <>
      <div className="flex flex-col overflow-auto px-10 gap-y-4 pb-4" id="section-to-print">
        {Array.isArray(recentTransactions) &&
          recentTransactions.length > 0 &&
          recentTransactions.map((x) => (
            <div className="shadow-lg mx-auto p-8 ">
              <div className="flex gap-x-7 gap-y-3">
                <div>
                  <label className="text-black text-xs">Amount</label>
                  <p className="font-medium">
                    {getDetail(x) === 'Fee' ? '+' : x?.transcationType?.toLowerCase() === 'credit' ? '+' : '-'}
                    &#x20b9;{x.total_fee ? x.total_fee : Array.isArray(x.amount) ? x.amount[0] : x.amount}
                  </p>
                </div>
                <div>
                  <label className="text-black text-xs">Transaction Details</label>
                  <p className="font-medium">{getDetail(x) === 'Fee' ? x.month + ' Month Fee' : getDetail(x)}</p>
                </div>
                <div>
                  <label className="text-black text-xs">Date</label>
                  <p className="font-medium">{moment(x.date).format('ll')}</p>
                </div>
                <div>
                  <label className="text-black text-xs">Time</label>
                  <p className="font-medium">{moment(x.date).format('hh:mm')}</p>
                </div>
              </div>
              {x.files.length > 0 && (
                <div>
                  <img className="w-auto h-56 mx-auto mt-6" src={x.files[0].location}></img>
                </div>
              )}
            </div>
          ))}
      </div>

      {selectedTransaction && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))', zIndex: '100' }}
        >
          <div className="bg-white w-5/12 text-sm block m-auto rounded-2xl relative ">
            <div className="w-full justify-between items-center flex px-4 py-4 border-b border-tableHeader">
              <span className="font-bold">
                {moment(selectedTransaction.date).format('MMM')} - {getDetail(selectedTransaction)}
              </span>
              <img
                src="assets/img/Close.svg"
                className=" h-3 cursor-pointer"
                alt=""
                onClick={() => selectTransaction(false)}
              />
            </div>
            <div
              className="text-lg p-4 px-6 flex justify-between items-center"
              style={{ borderBottom: '0.71px solid #E7E700' }}
            >
              <span>{`Amount: Rs. ${
                selectedTransaction.total_fee
                  ? selectedTransaction.total_fee
                  : Array.isArray(selectedTransaction.amount)
                  ? selectedTransaction.amount[0]
                  : selectedTransaction.amount
              }`}</span>
              <span>{`Time: ${moment(selectedTransaction.date).format('ll')}`}</span>
            </div>
            <div className="p-4 flex items-center" style={{ borderBottom: '0.71px solid #E7E700' }}>
              {selectedTransaction.files &&
                selectedTransaction.files.length > 0 &&
                (selectedTransaction?.files[0]?.location || selectedTransaction.files[0].link) && (
                  <img
                    src={selectedTransaction?.files[0]?.location || selectedTransaction.files[0].link}
                    alt=""
                    className="w-auto mx-auto h-96"
                  />
                )}
            </div>

            {/* <div className="w-1/3" /> */}
            <div className="flex items-end  justify-end my-4 px-6">
              <button
                type="submit"
                className="w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                onClick={() => handleAskFOrJustification(selectedTransaction._id)}
              >
                Ask for Justification
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedRequestDetail && (
        <RequestDetail
          selectedRequestDetail={selectedRequestDetail}
          onClose={() => {
            selectRequestDetail(null);
          }}
          refreshData={() => {
            getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
          }}
        />
      )}

      {selectedJustificationDetail && (
        <JustificationDetail
          onClose={() => {
            selectJustificationDetail(null);
          }}
          selectedJustificationDetail={selectedJustificationDetail}
        />
      )}

      <div className="w-full md:w-4/5">
        <div className="relative pb-10 ">
          {addCategory && (
            <div
              className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
              style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
            >
              <div className="bg-white w-11/12 md:w-4/12 text-sm block m-auto rounded-2xl relative">
                <span className="block font-bold p-4 px-4 border-b border-borderYellow">Add Category</span>
                <img
                  src="assets/img/Close.svg"
                  className="absolute top-5 right-4 h-3 cursor-pointer"
                  alt=""
                  onClick={() => {
                    setAddCategory(false);
                    setAmount(0);
                    setCategory('');
                  }}
                />
                <form className="mt-2 mb-4 mx-4 flex flex-wrap" autoComplete="off" onSubmit={handleAdd}>
                  <div className="mt-2 w-full relative font-semibold">
                    <label className="text-textDark text-sm">Category Name</label>
                    <input
                      type="text"
                      placeholder="Eg- Salaries(Non-Working Staff"
                      autoComplete="off"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      className="w-full rounded-3xl border border-borderLight text-xs px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="mt-4 mb-4 w-full relative font-semibold">
                    <label className="text-textDark text-sm">Budget Amount</label>
                    <input
                      type="text"
                      placeholder="Ex- Rs 2,50,000"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full rounded-3xl border border-borderLight text-xs px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="flex ml-auto relative font-semibold">
                    <div className="flex">
                      <button
                        type="submit"
                        className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border border-tableRowText text-tableRowText px-12 py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center "
                        onClick={() => setAddCategory(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs font-medium focus:outline-none flex justify-center items-center  bg-primary text-white"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {modify && (
            <div
              className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
              style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
            >
              <div className="bg-white w-11/12 md:w-4/12 text-sm block m-auto rounded-2xl relative">
                <span className="block font-bold p-4 px-6 border-b border-borderYellow">
                  {selectedCategory?.category?.category}
                </span>
                <img
                  src="assets/img/Close.svg"
                  className="absolute top-5 right-4 h-3 cursor-pointer"
                  alt=""
                  onClick={() => setModify(false)}
                />
                <form className="mt-2 mb-4 mx-6 flex flex-wrap" autoComplete="off" onSubmit={handleModify}>
                  <div className="mt-2 w-full relative font-semibold disabled">
                    <label className=" text-sm  opacity-50">Category Name</label>
                    <p
                      disabled
                      className="w-full h-10 rounded-3xl border border-tableRowText px-4 mt-2 py-2 focus:outline-none  opacity-50"
                    >
                      {selectedCategory?.category?.category}
                    </p>
                  </div>
                  {/* <div className="w-1/10" /> */}
                  <div className="mt-4 mb-2 w-full relative font-semibold ">
                    <label className=" text-sm  opacity-50">Budget Amount</label>
                    <p
                      disabled
                      className="w-full h-10 rounded-3xl border border-tableRowText px-4 mt-2 py-2 focus:outline-none  opacity-50"
                    >
                      {selectedCategory?.amount}
                    </p>
                  </div>
                  <div className="mt-2 mb-4 w-full relative font-semibold">
                    <label className=" text-sm">New Budget Amount</label>
                    <input
                      type="text"
                      placeholder="--"
                      value={newAmount}
                      onChange={(e) => setNewAmount(e.target.value)}
                      className="w-full h-10 rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="ml-auto relative font-semibold">
                    {/* <div className="w-1/3" /> */}
                    <div className="flex">
                      <button
                        className="mt-1 mr-4 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center border-tableRowText text-tableRowText"
                        onClick={(e) => {
                          e.preventDefault();
                          setModify(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary text-white"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {!showBudget && (
            <>
              {/* Transactions */}
              <div className="w-full bg-white rounded-xl h-auto">
                <div className="items-center px-3 md:px-6 py-3 border-b border-borderYellow">
                  <div className="lg:flex block sm:block justify-between items-center">
                    <div className="font-bold">
                      <p>Transactions</p>
                    </div>
                    <div className="flex">
                      <button
                        type="submit"
                        className=" border border-tableRowText text-tableRowText w-40 px-3 pl-4 py-2 rounded-3xl text-xs  mx-3 focus:outline-none flex justify-between items-center"
                        onClick={() => {}}
                      >
                        Overall
                        <img src="assets/img/expandIcon.svg" className="h-2 ml-16" alt="" />
                      </button>
                      <button
                        className=" border border-tableRowText text-tableRowText w-40 px-3 pl-4 py-2 rounded-3xl text-xs  mx-3 focus:outline-none flex justify-between items-center"
                        onClick={() => {}}
                      >
                        Yearly
                        <img src="assets/img/expandIcon.svg" className="h-2 ml-16 " alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap w-full">
                  <div className="w-full md:w-1/3 py-2 md:py-5 px-3 md:px-5 md:pr-0">
                    <div className="bg-yellow-50 rounded-xl block pl-6 py-4">
                      <p className="text-xs text-tableRowText"> Total Amount spent</p>
                      <p className="text-2xl text-textRed">
                        {' '}
                        {budget?.data && getAmountRounded(budget?.data?.totalExpense)}
                      </p>
                      <p className="text-xs text-tableRowText"> Total Budget</p>
                      <p className="text-2xl">
                        {' '}
                        {budget?.data && getAmountRounded(budget?.data?.totalBudget)}{' '}
                        <span className="text-lg">{`(${
                          budget?.data?.totalExpense > 0
                            ? ((budget?.data?.totalExpense / budget?.data?.totalBudget) * 100).toFixed(2)
                            : 0
                        }% Spent)`}</span>
                      </p>
                    </div>
                    <div className="bg-yellow-50 rounded-xl block pl-6 py-4 my-4">
                      <p className="text-xs text-tableRowText"> Total Amount Recieved</p>
                      <p className="text-2xl text-textGreen">
                        {budget?.data &&
                          budget?.data.fees &&
                          Array.isArray(budget?.data.fees) &&
                          getAmountRounded(
                            budget?.data.fees.reduce((acc, cuu) => (acc = acc + (+cuu?.amount || 0)), 0)
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="w-11/12 mx-auto md:w-2/3 py-2 md:py-4 overflow-auto px-6">
                    <div
                      className="bg-white m-auto mb-3 border rounded-2xl border-borderYellow overflow-hidden"
                      // style={{ width: '91%' }}
                    >
                      <div className="w-full flex mr-2 bg-tableHeader rounded-3xl">
                        <div className=" flex w-full relative px-4 text-xs font-medium">
                          <div className="w-3/12 border-r-2 py-2 px-3">Amount</div>
                          <div className="w-4/12 border-r-2 py-2 px-3">Transaction Detail</div>
                          <div className="w-3/12 border-r-2 py-2 px-3">Date</div>
                          <div className="w-2/12 py-2 px-3">Time</div>
                        </div>
                      </div>
                      <div className="customScrollBar" style={{ height: '50vh' }}>
                        {Array.isArray(recentTransactions) && recentTransactions.length > 0 ? (
                          recentTransactions.map((x) => (
                            <div className="w-full">
                              <div className="my-2  flex w-full relative px-4 py-2 text-xs text-black">
                                <div
                                  className={`w-3/12 px-2 ${
                                    getDetail(x) === 'Fee'
                                      ? 'text-textGreen'
                                      : x?.transcationType?.toLowerCase() === 'credit'
                                      ? 'text-textGreen'
                                      : 'text-textRed'
                                  } text-base flex items-center`}
                                >
                                  {/* {x.amount > 0 ? '+' + x.amount : x.amount} */}
                                  {getDetail(x) === 'Fee'
                                    ? '+'
                                    : x?.transcationType?.toLowerCase() === 'credit'
                                    ? '+'
                                    : '-'}
                                  &#x20b9;{x.total_fee ? x.total_fee : Array.isArray(x.amount) ? x.amount[0] : x.amount}
                                </div>
                                <div className="w-4/12 px-2 flex flex-col">
                                  <p className="text-sm ">
                                    {getDetail(x) === 'Fee' ? x.month + ' Month Fee' : getDetail(x)}
                                  </p>
                                  <p className="text-xxs text-black30">
                                    {x?.studentId ? x?.mode : x?.category?.category}
                                  </p>
                                </div>
                                <div className="w-3/12 px-2 flex items-center -mt-2">{moment(x.date).format('ll')}</div>
                                <div className="w-2/12 px-2 flex items-center -mt-2">
                                  {moment(x.date).format('hh:mm a')}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                            No Data Available
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:flex block sm:block justify-between items-center">
                  <div className="font-medium text-xl">{/* <p>Transactions</p> */}</div>
                  <div className="flex mb-8 mr-6">
                    <button
                      type="submit"
                      className="w-36 border px-6 py-2 rounded-3xl text-xs border-tableRowText text-tableRowText mr-6 focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        printDiv();
                      }}
                    >
                      Export will Bills
                    </button>
                    <ExcelExportBtn
                      data={excelData}
                      headers={excelHeaders}
                      fileName={'transactions.csv'}
                      width={'w-36'}
                    />
                  </div>
                </div>
              </div>

              {/* Budget graphs */}
              <div className="w-full bg-white rounded-xl h-auto mt-4 pb-4">
                <div className="items-center px-6 py-3 border-b border-borderYellow">
                  <div className="lg:flex block sm:block justify-between items-center">
                    <div className="font-bold">
                      <p>Budget</p>
                    </div>
                    <div className="flex">
                      <DropDown
                        data={['2023-2024']}
                        width={'w-36'}
                        margin={'mr-3'}
                        handleItemClick={(x) => {}}
                        otherClasses={'border-textLight text-tableRowText'}
                      />

                      {/* <button
                        className="w-36 border px-3  py-2 rounded-3xl text-xs text-white bg-primary ml-3 focus:outline-none "
                        onClick={() => {}}
                      >
                        Add Budget
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-3 grid-cols-1 m-2 px-2 gap-4">
                  {monthlyBudget.map((x) => (
                    <div className=" h-64 rounded-xl relative mt-2 border border-borderYellow flex flex-col">
                      <div className="pt-1 pb-1.5" style={{ height: '80%', width: '100%' }}>
                        <Chart
                          key={x.amount}
                          budget={x.amount}
                          spent={x.amount < x.expense ? x.amount : x.expense}
                          extra={x.amount < x.expense ? x.expense - x.amount : 0}
                        />
                      </div>

                      <div className="h-14 w-full flex items-center justify-between border-t border-borderYellow">
                        <span className="ml-6 text-tableRowText">{x.month}</span>
                        <button
                          className="w-28 h-9 mr-3 text-center text-xs  px-4 rounded-3xl text-tableRowText border border-tableRowText"
                          onClick={() => {
                            setMonth(x.month);
                            setShowBudget(true);
                            setCategoryBudget(x.category);
                            // window.scrollTo(0, 0);
                          }}
                        >
                          {' '}
                          Show More
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {role === 'owner' && (
                <>
                  <ExpenseJustification
                    selectJustificationDetail={selectJustificationDetail}
                    expenses={expenses}
                    selectTransaction={selectTransaction}
                  />
                  <ExpenseRequest selectRequestDetail={selectRequestDetail} expensesRequests={expensesRequests} />
                </>
              )}
            </>
          )}
          {showBudget && (
            <div className="w-full bg-white rounded-xl h-auto pb-4">
              <div className="items-center px-6 pt-3 pb-4 border-b border-borderYellow">
                <p className="text-xs pb-2 cursor-pointer">Budget & Transaction > Budget > {month}</p>
                <div className="lg:flex block sm:block justify-between items-center">
                  <div className="font-bold">
                    <p>{month}</p>
                  </div>
                  <div className="flex">
                    <div className="relative w-full ">
                      <DropDown
                        data={['List View', 'Pie Chart View']}
                        width={'w-40'}
                        margin={'mr-4'}
                        handleItemClick={(x) => {
                          setView(x);
                        }}
                      />
                    </div>
                    <div className="w-full">{/* <ExcelExportBtn data={[]} fileName={month + '-budget.csv'} /> */}</div>
                  </div>
                </div>
              </div>
              {view === 'List View' && (
                <div>
                  <div className="flex flex-wrap w-full ">
                    <div className="w-full py-4 overflow-auto">
                      <div
                        className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                        style={{ width: '96%' }}
                      >
                        <div className="w-full flex  bg-tableHeader rounded-3xl">
                          <div className="px-8 flex w-full relative text-left text-xs font-medium">
                            <div className="w-4/12 border-r-2 py-2">Category</div>
                            <div className="w-1/4 border-r-2 py-2 pl-4">Budget Amount</div>
                            <div className="w-1/4 border-r-2 py-2 pl-4">Amount Spent</div>
                            <div className="w-1/4 border-r-2 py-2 pl-4">Percentage</div>
                            {months.findIndex((x) => x === month) >=
                              months.findIndex((x) => x === MONTHS[new Date().getMonth()]) && (
                              <div className="w-1/4 py-2 pl-4">Action</div>
                            )}
                          </div>
                        </div>
                        <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                          {budgetCategories?.data?.data &&
                          Array.isArray(budgetCategories.data.data) &&
                          budgetCategories.data.data.length > 0 ? (
                            budgetCategories.data.data.map((x, idx) => {
                              return (
                                <div className="w-full " key={x.category?._id}>
                                  <div
                                    className={`px-8 justify-center items-center flex w-full relative text-left py-2 text-xs  ${
                                      budgetCategories.data.data.length - 1 !== idx && 'border-b border-borderYellow'
                                    }`}
                                  >
                                    <div className="w-4/12 py-2 text-black font-normal">{x.category?.category}</div>
                                    <div className="w-1/4 pl-4 text-tableRowText">{x.amount}</div>
                                    <div className="w-1/4 pl-4 text-tableRowText">{x.expense}</div>
                                    <div className="w-1/4 pl-4 text-tableRowText">
                                      {Math.round((x.expense / x.amount) * 100) + '%'}
                                    </div>
                                    {months.findIndex((x) => x === month) >=
                                      months.findIndex((x) => x === MONTHS[new Date().getMonth()]) && (
                                      <div className="w-1/4 pl-4 gap-x-4 flex">
                                        <button
                                          onClick={() => {
                                            setModify(true);
                                            selectCategory(x);
                                          }}
                                        >
                                          <img src="assets/img/edit.svg" alt="edit" />
                                        </button>
                                        <button
                                          onClick={() => {
                                            handleDelete(x.category._id);
                                          }}
                                        >
                                          <img src="assets/img/delete.svg" alt="delete" />
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                              No Data Available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex block sm:block justify-between items-center pb-4">
                    {/* <div className="font-medium text-xl flex ml-6">
                    <input
                      type="text"
                      placeholder="Enter Section Name"
                      className="w-full lg:w-max border px-3 py-2 rounded-3xl text-xs text-gray-400 mx-3 focus:outline-none flex justify-between items-center text-center"
                    />
                    <input
                      type="text"
                      placeholder="Enter Budget Amount"
                      className="w-full lg:w-max border px-3 py-2 rounded-3xl text-xs text-gray-400 mx-3 focus:outline-none flex justify-between items-center text-center"
                    />
                  </div> */}
                    <div className="flex ml-auto mr-10 justify-between items-center">
                      {months.findIndex((x) => x === month) >=
                        months.findIndex((x) => x === MONTHS[new Date().getMonth()]) && (
                        <button
                          className="w-36 lg:w-max border px-6 py-2 rounded-3xl text-xs text-white bg-primary mr-1   focus:outline-none flex justify-center items-center"
                          onClick={() => {
                            setAddCategory(true);
                          }}
                        >
                          Add Section
                        </button>
                      )}
                      <button
                        className="w-36 lg:w-max border px-6 py-2 rounded-3xl text-xs text-white bg-primary mx-3 focus:outline-none flex justify-center items-center"
                        onClick={() => setShowBudget(false)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {view === 'Pie Chart View' && (
                <div className="grid md:grid-cols-4 grid-cols-1 gap-4 p-4">
                  {categoryBudget.map((x) => (
                    <div className="w-full h-23 rounded-xl p-4 relative border flex flex-col border-borderYellow">
                      <div className="w-full h-3/5 m-auto ">
                        <PieChart
                          values={[
                            Math.floor(((x.amount - x.expense) / x.amount) * 100),
                            Math.ceil((x.expense / x.amount) * 100),
                          ]}
                        />
                      </div>
                      <div className="w-full items-center justify-between px-2 mt-auto text-tableRowText ">
                        <p className="text-black font-semibold py-0.5 text-sm">{x.category.category}</p>
                        <p className="text-xxs font-semibold py-0.5 opacity-70">Budget - {x.amount}</p>
                        <p className="text-xxs font-semibold py-0.5 opacity-70">Spent - {x.expense}</p>
                        <button
                          className="w-full border mt-2 text-center text-xs font-semibold px-4 py-2 rounded-3xl text-tableRowText border-tableRowText  "
                          onClick={() => {
                            setModify(true);
                            selectCategory(x);
                          }}
                        >
                          {' '}
                          Modify
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="w-full h-23 border-2 rounded-xl relative ">
                    <div
                      className="w-11/12 border-2 m-auto rounded-xl absolute top-2 bottom-2 left-2 right-2 flex justify-center items-center text-2xl text-gray-200 cursor-pointer"
                      onClick={() => {
                        setAddCategory(true);
                      }}
                    >
                      <p>Add Section</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Budget;
