import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { GET_ALL_LECTURES, CREATE_SUMMARY, UPDATE_SUMMARY, UPLOAD_NOTES, GET_SCHEDULE } from '../../../api/urls/notes';
import Notes from './Notes';
import axios from 'axios';
const Lectures = ({ subjects = [] }) => {
  const [allLectures, setAllLectures] = useState([]);
  const [selectedLecture, selectLecture] = useState();
  const [selectedLectureForNote, selectLectureForNote] = useState();
  const [selectedFile, setFile] = useState();
  const [filter, setFilter] = useState();

  const file = useRef();

  const [formData, setFormData] = useState({
    lesson: '',
    title: '',
    summary: '',
    isLiveSession: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [selectedTopic, selectTopic] = useState(false);

  const [lectures, getAllLectures] = useApi();
  const [summaryRes, summaryOperation] = useApi();
  const [uploadRes, uploadFile] = useApi();
  const [scheduleRes, getSchedule] = useApi();

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData?.lesson) str.push('Lesson');
    if (!formData?.title?.trim()) str.push('Title');
    if (!formData?.summary.trim()) str.push('Lecture Summary');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    const body = {
      ...formData,
      date: selectedLecture.lectureDate,
    };

    if (selectedLecture.summary) {
      summaryOperation(
        UPDATE_SUMMARY(
          selectedLecture.class._id,
          selectedLecture.section._id,
          selectedLecture.subjectId,
          selectedLecture.summary._id
        ),
        'PUT',
        body
      );
    } else {
      summaryOperation(
        CREATE_SUMMARY(selectedLecture.class._id, selectedLecture.section._id, selectedLecture.subjectId),
        'POST',
        body
      );
    }
  };

  useEffect(() => {
    if (!summaryRes.isLoading && !summaryRes.isError && summaryRes.status) {
      alert('SUCCESS');
      handleClose();
      getAllLectures(GET_ALL_LECTURES, 'GET');
    }

    if (summaryRes.isError) {
      alert('FAILED');
    }
  }, [summaryRes]);

  useEffect(() => {
    getAllLectures(GET_ALL_LECTURES, 'GET');
    getSchedule(GET_SCHEDULE, 'GET');
  }, []);

  useEffect(() => {
    const { isLoading, isError, data } = lectures;
    if (!isLoading && !isError && data) {
      setAllLectures(data);
    }
  }, [lectures]);

  useEffect(() => {
    if (selectedLecture && selectedLecture.summary) {
      setFormData({
        lesson: selectedLecture.summary.lesson,
        title: selectedLecture.summary.title,
        summary: selectedLecture.summary.summary,
      });
    }
  }, [selectedLecture]);

  const handleClose = () => {
    selectLecture();
    setFormData({
      lesson: '',
      title: '',
      summary: '',
    });
  };

  const handleUploadClick = (x, lectureDate) => {
    file.current.click();
    selectLectureForNote({
      class: x.class,
      section: x.section,
      subject: x.subject,
      subjectId: x.subjectId,
      lectureDate: lectureDate,
    });
  };

  useEffect(() => {
    if (selectedFile) {
      const body = new FormData();
      body.append('notes', selectedFile);
      body.append('date', selectedLectureForNote.lectureDate);
      uploadFile(
        UPLOAD_NOTES(
          selectedLectureForNote.class._id,
          selectedLectureForNote.section._id,
          selectedLectureForNote.subjectId
        ),
        'POST',
        body
      );
    }
  }, [selectedFile]);

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && [200, 201, 202].includes(uploadRes.status)) {
      alert('Success');
      getAllLectures(GET_ALL_LECTURES, 'GET');
      setFile(null);
      file.current.value = null;
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('Failed');
      setFile(null);
      file.current.value = null;
    }

    selectLectureForNote();
  }, [uploadRes]);

  const lessons = subjects?.find((subject) => subject?.subjectId === selectedLecture?.subjectId) || [];

  let _allLectures = allLectures || [];
  if (filter) {
    _allLectures = allLectures.map((m) => {
      if (filter[m.subject] === 'Upcoming') {
        return {
          ...m,
          lectures: m.lectures.filter((d) => moment(d.lectureDate).isAfter(new Date())),
        };
      } else if (filter[m.subject] === 'Delivered') {
        return {
          ...m,
          lectures: m.lectures.filter((d) => moment(d.lectureDate).isBefore(new Date())),
        };
      } else {
        return {
          ...m,
        };
      }
    });
  }

  const generateNotes = async (topic, index) => {
    selectTopic(index);
    const prompt = `Generate notes for topic:\n\n${topic}`;
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: prompt,
                },
              ],
            },
          ],
          max_tokens: 2000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer sk-proj-2GYEsmjjf7T48hED44WGT3BlbkFJbmWXUqIup8gjYP9xjieX',
          },
        }
      );
      setNotes(response?.data?.choices?.[0]?.message?.content);
      // console.log(response?.data?.choices?.[0]?.message?.content);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  console.log({ notes });
  const renderSchedule = (schedule) => {
    if (!schedule) return null;

    return schedule
      .split('\n')
      .slice(2)
      .map((line, index) => {
        if (line.replaceAll('*', '').trim().includes('Topic: ')) {
          const topic = line.replaceAll('*', '').split('Topic: ')[1]?.split(',')[0] || '';
          return (
            <div key={index} className="flex justify-between gap-x-4 text-primary">
              <span className="font-semibold">{line.replaceAll('*', '')}</span>
              {topic && (
                <button
                  onClick={() => generateNotes(topic, index)}
                  className={`border border-primary w-36 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                    isLoading && index === selectedTopic ? 'opacity-50' : 'opacity-100'
                  }`}
                  disabled={isLoading}
                >
                  {isLoading && index === selectedTopic ? 'Generating...' : 'Generate Notes'}
                </button>
              )}
            </div>
          );
        }

        if (line.trim().replaceAll('*', '').includes('Day')) {
          const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2">
              <p className="font-medium text-gray-400">
                <i>{day}</i>
              </p>
            </div>
          );
        }

        if (line.trim().replaceAll('*', '').includes('Week')) {
          const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2 font-semibold text-lg">
              <p className="">{day}</p>
            </div>
          );
        }

        return <div key={index}>{line}</div>;
      });
  };

  return (
    <>
      <div className="relative w-full md:w-4/5 pb-10">
        <input type="file" ref={file} hidden onChange={(e) => setFile(e.target.files[0])} />

        {notes && (
          <Notes
            notes={notes}
            onClose={() => {
              setNotes(null);
            }}
          />
        )}
        {scheduleRes?.data &&
          Array.isArray(scheduleRes?.data) &&
          scheduleRes?.data?.map((x) => (
            <div className="w-full bg-white rounded-xl h-auto mb-5">
              <div className="items-center px-6 py-3 border-b border-borderYellow">
                <div className="lg:flex block sm:block justify-between items-center">
                  <div className="font-bold w-5/12">
                    <p>{`${x?.subjectId?.subjectName}-${x?.subjectId?.subjectCode} (${x?.classId?.name}-${x?.sectionId?.name})`}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full overflow-auto px-6 customScrollBar" style={{ maxHeight: '50vh' }}>
                <div className="w-full py-4 overflow-auto">{renderSchedule(x?.content)}</div>
              </div>
            </div>
          ))}
      </div>

      {/* TODO: THIS IS HIDDEN FOR DEMO PURPOSE */}
      <div className="relative w-full md:w-4/5 pb-10 hidden">
        <input type="file" ref={file} hidden onChange={(e) => setFile(e.target.files[0])} />

        {selectedLecture && (
          <div
            className="flex h-full w-full fixed left-0 top-0 z-30"
            style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
          >
            <div className="bg-white w-11/12 md:w-6/12 text-sm m-auto mt-10 rounded-2xl relative">
              <div className="border-b border-borderYellow flex items-center py-4">
                <span className="block font-bold px-6  mr-auto text-textBlue1 ">{`${
                  selectedLecture?.subject
                } - ${moment(selectedLecture.lectureDate).format('ll')}`}</span>
                {/* {selectedLecture.summary && (
                <button
                  type="submit"
                  className="border border-primary px-7 rounded-3xl text-xs font-bold focus:outline-none text-center py-2 text-primary"
                  // onClick={() => setView(false)}
                >
                  Edit Summary
                </button>
              )} */}
                <img
                  src="assets/img/Close.svg"
                  className="mx-4 h-3 cursor-pointer"
                  alt=""
                  onClick={() => handleClose()}
                />
              </div>
              <form className="m-6" autoComplete="off" onSubmit={handleSubmit}>
                <div className="my-2 relative w-full">
                  <span className="text-sm font-medium">
                    Lesson<span className="text-red-500 ml-1">*</span>
                  </span>
                  {/* <div
                className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
                style={{ overflowY: 'scroll' }}
              >
                <p>{selectedLecture?.title || ''}</p>
              </div> */}
                  <DropDown
                    data={lessons?.course?.course?.map((x) => x.lessonName) || []}
                    width={'w-full'}
                    handleItemClick={(x) => {
                      setFormData({ ...formData, lesson: x });
                    }}
                    margin={'mt-2'}
                    otherClasses={'border-borderLight'}
                    title={formData.lesson || selectedLecture?.summary?.lesson || 'Select Lesson'}
                  />
                </div>
                <div className="my-4 relative w-full">
                  <span className="text-sm font-medium">
                    Title<span className="text-red-500 ml-1">*</span>
                  </span>
                  {/* <div
                className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
                style={{ overflowY: 'scroll' }}
              >
                <p>{selectedLecture?.title || ''}</p>
              </div> */}
                  <input
                    type="text"
                    name="title"
                    required
                    // placeholder="All"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-4 relative  w-full">
                  <span className="text-sm font-medium">
                    Lecture Summary<span className="text-red-500 ml-1">*</span>
                  </span>
                  {/* <div
                className="mt-2 w-full relative font-normal border border-gray-400 rounded-lg p-4 customScrollBar"
                style={{ overflowY: 'scroll' }}
              >
                <p>{selectedLecture?.summary || ''}</p>
              </div> */}
                  <textarea
                    rows={8}
                    name="summary"
                    required
                    // placeholder="All"
                    value={formData.summary}
                    onChange={handleInputChange}
                    className="w-full text-xs rounded-3xl border border-borderLight text-tableRowText px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="pt-3 gap-x-4 w-full flex justify-end ">
                  {selectedLecture.notes && (
                    <a
                      className="border w-40 rounded-3xl text-xs font-semibold focus:outline-none text-center py-2 bg-primary text-white"
                      href={selectedLecture.notes.location}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Notes
                    </a>
                  )}
                  {summaryRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                  ) : (
                    <button
                      type="submit"
                      className="border w-36 rounded-3xl text-xs font-semibold focus:outline-none text-center py-2 bg-primary text-white"
                    >
                      {selectedLecture.summary ? 'Edit' : 'Submit'}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
        {lectures.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
        ) : _allLectures.length > 0 ? (
          _allLectures.map((x) => (
            <div className="w-full bg-white rounded-xl h-auto mb-5">
              <div className="items-center px-6 py-3 border-b border-borderYellow">
                <div className="lg:flex block sm:block justify-between items-center">
                  <div className="font-bold w-5/12">
                    <p>{`${x?.subject} (${x?.class?.name}-${x?.section?.name})`}</p>
                  </div>

                  <div className="relative mx-2 w-2/12">
                    <DropDown
                      data={['All Lectures', 'Delivered', 'Upcoming']}
                      width={'w-full'}
                      handleItemClick={(c) => {
                        setFilter({
                          ...filter,
                          [x.subject]: c,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap w-full h-1/4">
                <div className="w-full py-4 overflow-auto">
                  <div
                    className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                    style={{ width: '96%' }}
                  >
                    <div className="w-full flex bg-tableHeader rounded-3xl">
                      <div className="flex w-full relative text-left px-4 text-xs font-medium">
                        <div className="w-2/12 border-r-2 py-2 px-2">Lecture No</div>
                        <div className="w-4/12 border-r-2 py-2 px-2">Lecture Date</div>
                        <div className="w-3/12 border-r-2 py-2 px-2">Summary</div>
                        <div className="w-3/12 border-r-2 py-2 px-2">Notes</div>
                        <div className="w-3/12 py-2 px-2">Status</div>
                      </div>
                    </div>
                    <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                      {Array.isArray(x.lectures) && x.lectures.length > 0 ? (
                        x?.lectures.map((y, idx) => (
                          <div className="w-full">
                            <div
                              className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                                x.lectures.length - 1 !== idx && 'border-b border-borderYellow'
                              }`}
                            >
                              <div className="w-2/12 px-2 text-tableRowText">
                                {idx + 1 < 10 ? '0' + (idx + 1) : idx + 1}
                              </div>
                              <div className="w-4/12 px-2 text-tableRowText">{moment(y.lectureDate).format('ll')}</div>
                              <div className="w-3/12 px-2">
                                <button
                                  className="border border-primary py-2 rounded-3xl text-primary w-10/12 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                                  onClick={() =>
                                    selectLecture({
                                      class: x.class,
                                      section: x.section,
                                      subject: x.subject,
                                      subjectId: x.subjectId,
                                      lectureDate: y.lectureDate,
                                      summary: y.summary,
                                      notes: y.notes,
                                    })
                                  }
                                >
                                  {!y.summary ? 'Upload' : 'View'}
                                </button>
                              </div>
                              <div className="w-3/12 px-2">
                                {y.notes ? (
                                  <a
                                    className="border border-primary py-2 rounded-3xl text-primary w-10/12 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                                    href={y.notes.location}
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Download
                                  </a>
                                ) : (
                                  <button
                                    className="border border-primary py-2 rounded-3xl text-primary w-10/12 font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                                    onClick={() => handleUploadClick(x, y.lectureDate)}
                                  >
                                    Upload
                                  </button>
                                )}
                              </div>
                              <div className="w-3/12 px-2 text-tableRowText">
                                {moment(y.lectureDate).isAfter(new Date()) ? 'Upcoming' : 'Lecture Delivered'}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                          No Data Available
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
        )}
      </div>
    </>
  );
};

export default Lectures;
