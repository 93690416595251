import React from 'react';
import Attendance from './Attendance';
import CourseDetails from './CourseDetails';
import { useLocation } from 'react-router-dom';
import LectureSummary from './LectureSummary';
import Fee from './Fee';
import Feedback from './Feedback';
import Dashboard from './Dashboard';
import LiveSessions from './LiveSessions';
import Assignment from './Assignment';
import MarksResult from './MarksResult';
import EventsTimetable from './EventsTimetable';
import NextAdmission from './NextAdmission';
import MeetingRemarks from './MeetingRemarks';
import HomeWork from './HomeWork';
import AcademicCalender from '../Principal/AcademicCalender';

function Parents() {
  const location = useLocation();
  return (
    <div>
      {location.search === '' && <Dashboard />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('Attendance') && <Attendance />}
      {location.search.includes('Events') && <EventsTimetable />}
      {location.search.includes('Course') && <CourseDetails />}
      {location.search.includes('Lecture') && <LectureSummary />}
      {location.search.includes('Sessions') && <LiveSessions />}
      {location.search.includes('Assignment') && <Assignment role="Parent" />}
      {location.search.includes('Marks') && <MarksResult />}
      {location.search.includes('Feedback') && <Feedback role="Parent" />}
      {location.search.split('=')[1] === 'Fee' && <Fee />}
      {location.search.includes('parentAdmissionRes') && <NextAdmission role="Parent" />}
      {location.search.includes('Meeting/Remarks') && <MeetingRemarks />}
      {location.search.includes('HomeWork') && <HomeWork />}
    </div>
  );
}

export default Parents;
