import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'ROLEDin ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Date - From', key: 'from' },
  { label: 'Date - To', key: 'to' },
  { label: 'Total Days', key: 'days' },
  { label: 'Status', key: 'status' },
];

function StaffLeaves({ title, openDetails, history }) {
  const [sort, setSort] = useState('');
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (history && Array.isArray(history)) {
      let x = history.map((x) => {
        return {
          name: x.user_id?.full_name,
          username: x.user_id?.username,
          from: moment(x.date).format('ll') || '',
          to: moment(x.endDate).format('ll'),
          days: x.days,
          status: x.status ? 'Approved' : x.rejected ? 'Rejected' : 'Pending',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [history]);

  let filteredData = history || [];

  if (sort && sort !== 'All') {
    if (sort === 'Rejected') {
      filteredData = filteredData.filter((x) => x.rejected);
    } else if (sort === 'Approved') {
      filteredData = filteredData.filter((x) => x.status);
    } else if (sort === 'Pending') {
      filteredData = filteredData.filter((x) => !x.rejected && !x.status);
    }
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-full md:w-1/3">
            <p>{title}</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'leaves.csv'} text={'Export Data'} />

            <DropDown
              data={['All', 'Pending', 'Approved', 'Rejected']}
              width={'w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/6 border-r-2 py-2 px-2">ROLEDin ID</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Date - From</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Date - To</div>
                <div className="w-1/6 border-r-2 py-2 px-2">Total Days</div>
                <div className="w-1/6 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {filteredData.length > 0 ? (
                filteredData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          filteredData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/6 px-2 text-tableRowText ">{x.user_id?.username}</div>
                        <div className="w-1/6 px-2 text-textDark ">{x.user_id?.full_name}</div>
                        <div className={`w-1/6 px-2 text-textDark `}>
                          {x.date ? moment(x.date).format('ll') : moment(x.startDate).format('ll')}
                        </div>
                        <div className={`w-1/6 px-2 text-textDark `}>{moment(x.endDate).format('ll')}</div>
                        <div className={`w-1/6 px-2 text-textDark `}>{x.days}</div>
                        <div className={`w-2/12 px-2 `}>
                          {x.status ? (
                            'Approved'
                          ) : x.rejected ? (
                            'Rejected'
                          ) : (
                            <button
                              className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-borderGrey text-tableRowText"
                              onClick={() => openDetails(x)}
                            >
                              View
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffLeaves;
