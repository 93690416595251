import React, { useState, useEffect } from 'react';
import StaffOnLeaveList from '../../../components/Owner/Staff/StaffOnLeaveList';
import NonTeachingStaff from '../../../components/Owner/Staff/NonTeachingStaff';
import TeachingStaff from '../../../components/Owner/Staff/TeachingStaff';
import StaffOnLeave from '../../../components/Owner/Staff/StaffOnLeave';
import StaffProfile from '../../../components/Owner/Staff/StaffProfile';
import Message from '../../../components/Owner/Staff/Message';
import StaffList from '../../../components/Owner/Staff/StaffList';
import StaffRemoved from '../../../components/Admin/Staff/StaffRemoved';
import StaffRemovePopup from '../../../components/Admin/Staff/StaffRemovePopup';

import useApi from '../../../api/useApi';
import { GET_TEACHING_STAFF, GET_NON_TEACHING_STAFF, GET_REMOVED_STAFF } from '../../../api/urls/user';
import { STAFF_TODAY_ATTENDANCE } from '../../../api/urls/attendance';

const Staff = () => {
  const [createStaff, setCreateStaff] = useState(false);
  const [search, setSearch] = useState(false);
  const [showList, setShowList] = useState(false);
  const [message, setMessage] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [staffSearch, setStaffSearch] = useState(false);
  const [showRemovePopUp, setRemovePopUp] = useState(false);
  const [staffRole, setStaffRole] = useState('');
  const [searchedData, setSearchedData] = useState([]);
  const [leaveSearchedData, setLeaveSearchedData] = useState([]);

  const [selectedStaff, selectStaff] = useState();
  const [teachingStaff, setTeachingStaff] = useState([]);
  const [nonTeachingStaff, setNonTeachingStaff] = useState([]);
  const [staffLeaves, setStaffLeaves] = useState();

  const [{ isLoading, isError, data }, getTeachingStaff] = useApi();
  const [nonTeachingState, getNonTeachingStaff] = useApi();
  const [removedStaff, getRemovedStaff] = useApi();
  const [staffLeaveRes, getStaffLeave] = useApi();

  useEffect(() => {
    if (selectedStaff) tooglePopUp();
  }, [selectedStaff]);

  const handleClose = () => {
    selectStaff(null);
    tooglePopUp();
  };

  useEffect(() => {
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
    getNonTeachingStaff(GET_NON_TEACHING_STAFF, 'GET');
    getRemovedStaff(GET_REMOVED_STAFF, 'GET');

    getStaffLeave(STAFF_TODAY_ATTENDANCE, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setTeachingStaff(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    if (!nonTeachingState.isLoading && !nonTeachingState.isError && nonTeachingState.data) {
      setNonTeachingStaff(nonTeachingState.data);
    }
  }, [nonTeachingState]);

  useEffect(() => {
    if (!staffLeaveRes.isLoading && !staffLeaveRes.isError && staffLeaveRes.data) {
      setStaffLeaves(staffLeaveRes?.data.filter((x) => x.type === 'leave'));
    }
  }, [staffLeaveRes]);

  const tooglePopUp = () => {
    setCreateStaff((prev) => !prev);
  };

  const toggleRemoveStaffPopup = () => {
    setRemovePopUp((prev) => !prev);
  };

  const handleOpenMsg = (x) => {
    setShowList(false);
    setSearch(false);
    setMessage(x);
  };

  const handleOpenViewMore = (x) => {
    setStaffSearch(false);
    selectStaff(x);
  };

  const refreshList = () => {
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
    getNonTeachingStaff(GET_NON_TEACHING_STAFF, 'GET');
    getRemovedStaff(GET_REMOVED_STAFF, 'GET');
  };

  return (
    <>
      {createStaff && (
        <StaffProfile
          handleClose={handleClose}
          staffRole={staffRole}
          selectedStaff={selectedStaff}
          refreshList={refreshList}
        />
      )}

      {showRemovePopUp && (
        <StaffRemovePopup
          handleClose={toggleRemoveStaffPopup}
          refreshList={refreshList}
          staff={[...teachingStaff, ...nonTeachingStaff]}
        />
      )}

      <div className={`w-full md:w-4/5 ${createStaff && 'hidden'}`}>
        <div>
          {(search || showList) && (
            <StaffOnLeaveList
              setSearch={setSearch}
              searchName={searchName}
              setShowList={setShowList}
              isSearch={search}
              handleOpenMsg={handleOpenMsg}
              data={leaveSearchedData}
            />
          )}
          {staffSearch && (
            <StaffList
              searchedData={searchedData}
              setSearch={setStaffSearch}
              searchName={searchName}
              handleOpenViewMore={handleOpenViewMore}
            />
          )}
          {message && <Message setMessage={setMessage} message={message} />}

          <div className="relative pb-10 ">
            <StaffOnLeave
              setSearch={setSearch}
              setShowList={setShowList}
              setSearchName={setSearchName}
              staffLeaves={staffLeaves}
              setSearchedData={setLeaveSearchedData}
            />

            <TeachingStaff
              setCreateStaff={setCreateStaff}
              setSearch={setStaffSearch}
              selectStaff={selectStaff}
              setSearchName={setSearchName}
              data={teachingStaff}
              setStaffRole={setStaffRole}
              setSearchedData={setSearchedData}
              isLoading={isLoading}
            />

            <NonTeachingStaff
              setCreateStaff={setCreateStaff}
              setSearch={setStaffSearch}
              selectStaff={selectStaff}
              setSearchName={setSearchName}
              data={nonTeachingStaff}
              setStaffRole={setStaffRole}
              setSearchedData={setSearchedData}
              isLoading={nonTeachingState.isLoading}
            />

            <StaffRemoved
              toggleRemoveStaffPopup={toggleRemoveStaffPopup}
              setSearch={setStaffSearch}
              setSearchName={setSearchName}
              data={removedStaff}
              setSearchedData={setSearchedData}
              selectStaff={selectStaff}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Staff;
