import React, { useState, useEffect, useRef } from 'react';
import Calender from '../../../components/Admin/Event/Calender';
import CreateEvent from '../../../components/Admin/Event/CreateEvent';
import EventDetails from '../../../components/Admin/Event/EventDetails';
import Events from '../../../components/Admin/Event/Events';
import ExamDetails from '../../../components/Admin/Event/ExamDetails';
import ExamDates from '../../../components/Teacher/AdditionalResponsibility/ExamDates';
import { TimetableDataTable } from '../../../components/Teacher/AdditionalResponsibility/TimetableDataTable';
import CreateTimeTable from '../../../components/Teacher/AdditionalResponsibility/CreateTimeTable';

import useApi from '../../../api/useApi';
import { EVENT } from '../../../api/urls/timeTable';
import { SUBJECT, CLASS_SECTIONS } from '../../../api/urls/institute';
import { ALL_CLASSES_TIMETABLE, TIMETABLE_DELETE } from '../../../api/urls/timeTable';
import { GET_TEACHING_STAFF } from '../../../api/urls/user';
import DropDown from '../../../components/UI/DropDown';
import ExcelExportBtn from '../../../components/UI/ExcelExportBtn';

const csvTemplate = [
  'Subject',
  'SubjectCode',
  'Unit Test-1',
  'Unit Test-2',
  'Term Exam-1',
  'Unit Test-3',
  'Term Exam-2',
];

function index() {
  const [openEvent, setOpenEvent] = useState(false);
  const [openExamDetails, setOpenExamDetails] = useState(false);
  const [selectedEvent, selectEvent] = useState();
  const [events, setEvents] = useState([]);

  const [openCreate, setOpenCreate] = useState(false);
  const [staff, setStaff] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [selectedClass, selectClass] = useState();
  const [classes, setClasses] = useState([]);
  const [filter, setFilter] = useState();

  const [classSections, getClassSections] = useApi();
  const [staffResponse, getTeachingStaff] = useApi();
  const [subjectResponse, getSubjects] = useApi();
  const [deleteRes, deleteTimetable] = useApi();
  const [{ isLoading, isError, data }, getEvents] = useApi();

  useEffect(() => {
    getEvents(EVENT, 'GET');
    getClassSections(ALL_CLASSES_TIMETABLE, 'GET');
    getTeachingStaff(GET_TEACHING_STAFF, 'GET');
  }, []);

  const refreshEvents = () => {
    getEvents(EVENT, 'GET');
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setEvents(data);
    }
  }, [isLoading, isError, data]);

  const toggleOpenEvent = () => {
    setOpenEvent((prev) => !prev);
  };

  const toggleOpenExamDetails = () => {
    setOpenExamDetails((prev) => !prev);
  };

  useEffect(() => {
    if (selectedEvent) {
      toggleOpenEvent();
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (!staffResponse.isLoading && !staffResponse.isError && staffResponse.data) {
      setStaff(staffResponse.data);
    }
  }, [staffResponse]);

  useEffect(() => {
    if (!subjectResponse.isLoading && !subjectResponse.isError && subjectResponse.data) {
      setSubject(subjectResponse.data);
    }
  }, [subjectResponse]);

  useEffect(() => {
    if (!classSections.isLoading && !classSections.isError && classSections.data) {
      setClasses(classSections.data);
    }
  }, [classSections]);

  const handleCreate = (x) => {
    window.scrollTo(0, 0);
    selectClass(x);
    setOpenCreate(true);
  };

  useEffect(() => {
    if (selectedClass) getSubjects(SUBJECT(selectedClass.classInfo._id), 'GET');
  }, [selectedClass]);

  const refreshData = () => {
    getClassSections(ALL_CLASSES_TIMETABLE, 'GET');
  };

  const handleDelete2 = (id) => {
    deleteTimetable(TIMETABLE_DELETE(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status) {
      refreshData();
    }

    if (deleteRes.isError && deleteRes.data) {
      alert(deleteRes.data);
    }
  }, [deleteRes]);

  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [selectedClassForCSV, setSelectedClassFOrCSV] = useState();
  const [selectedClassForDates, selectClassForDates] = useState();

  const csvRef = useRef();

  const [classSectionResponse, getClassSections2] = useApi();
  const [postDatesResponse, postDates] = useApi();
  const [deleteDatesResponse, deleteDates] = useApi();
  const [openExamDates, setOpenExamDates] = useState(false);

  useEffect(() => {
    getClassSections2(CLASS_SECTIONS, 'GET');
  }, []);
  const [allClasses2, setAllClasses2] = useState([]);

  useEffect(() => {
    if (!classSectionResponse.isLoading && !classSectionResponse.isError && classSectionResponse.data) {
      setAllClasses2(classSectionResponse.data.studentInfo);
    }
  }, [classSectionResponse]);

  const handleFileSelect = (x) => {
    csvRef.current.click();
    setSelectedClassFOrCSV(x);
  };

  useEffect(() => {
    if (csvFile) {
      submit();
    }
  }, [csvFile]);

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };

    reader.readAsText(file);
  };

  const processCSV = (str, delim = ',') => {
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delim)
      .map((x) => x.replace('\r', ''));

    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row) => {
      const values = row.split(delim).map((x) => x.replace('\r', '').replace(/['"]+/g, ''));

      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    setCsvArray(newArray);
  };

  useEffect(() => {
    if (csvArray.length > 0) {
      const body = {
        examDates: csvArray,
        uniqueIndentity: selectedClassForCSV.class + '-' + selectedClassForCSV.section,
      };
      postDates(EXAM_DATES(selectedClassForCSV.classId, selectedClassForCSV.sectionId), 'POST', body);
    }
  }, [csvArray]);

  useEffect(() => {
    if (
      !postDatesResponse.isLoading &&
      !postDatesResponse.isError &&
      [200, 201, 202].includes(postDatesResponse.status)
    ) {
      getClassSections(CLASS_SECTIONS, 'GET');
      setSelectedClassFOrCSV(null);
      setCsvArray([]);
      setCsvFile(null);
      csvRef.current.value = null;
      alert('Success');
    }

    if (!postDatesResponse.isLoading && postDatesResponse.isError) {
      alert('Failed');
    }
  }, [postDatesResponse]);

  const handleDelete = (x) => {
    deleteDates(DELETE_EXAM_DATES(x.class + '-' + x.section), 'DELETE');
  };

  useEffect(() => {
    if (
      !deleteDatesResponse.isLoading &&
      !deleteDatesResponse.isError &&
      [200, 201, 202].includes(deleteDatesResponse.status)
    ) {
      getClassSections(CLASS_SECTIONS, 'GET');
      alert('Success');
    }

    if (!deleteDatesResponse.isLoading && deleteDatesResponse.isError) {
      alert('Failed');
    }
  }, [deleteDatesResponse]);

  useEffect(() => {
    if (selectedClassForDates) {
      setOpenExamDates(true);
    } else {
      setOpenExamDates(false);
    }
  }, [selectedClassForDates]);

  let _classes = allClasses2 || [];
  if (filter && filter !== 'All') {
    _classes = allClasses2.filter((x) => {
      if (filter === 'Pre - Primary') {
        return x.class.toLowerCase().includes('kg') || x.class.toLowerCase().includes('nursery');
      } else if (filter === 'Senior - Secondary') {
        return x.class.toLowerCase().includes('11') || x.class.toLowerCase().includes('12');
      } else if (filter === 'Secondary') {
        return x.class.toLowerCase().includes('9') || x.class.toLowerCase().includes('10');
      } else if (filter === 'Middle') {
        return (
          x.class.toLowerCase().includes('6') ||
          x.class.toLowerCase().includes('7') ||
          x.class.toLowerCase().includes('8')
        );
      } else {
        return (
          (x.class.toLowerCase().includes('1') &&
            !x.class.toLowerCase().includes('11') &&
            !x.class.toLowerCase().includes('12')) ||
          (x.class.toLowerCase().includes('2') && !x.class.toLowerCase().includes('12')) ||
          x.class.toLowerCase().includes('3') ||
          x.class.toLowerCase().includes('4') ||
          x.class.toLowerCase().includes('5')
        );
      }
    });
  }

  return (
    <div className="w-full md:w-4/5">
      {openEvent && <EventDetails toggleOpenEvent={toggleOpenEvent} selectedEvent={selectedEvent} />}
      {openExamDetails && <ExamDetails toggleOpenExamDetails={toggleOpenExamDetails} />}
      {openCreate && (
        <CreateTimeTable
          setOpenCreate={setOpenCreate}
          staff={staff}
          subjects={subjects}
          selectedClass={selectedClass}
          refreshData={refreshData}
          isView={selectedClass?.timeSlot.length > 0}
        />
      )}
      {openExamDates && (
        <ExamDates
          setOpenExamDates={setOpenExamDates}
          selectedClassForDates={selectedClassForDates}
          selectClassForDates={selectClassForDates}
        />
      )}

      <CreateEvent refreshEvents={refreshEvents} />
      <Events selectEvent={selectEvent} events={events} isLoading={isLoading} />
      <Calender events={events} />

      <TimetableDataTable
        title={'TimeTable'}
        setOpenCreate={setOpenCreate}
        classes={classes}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
      />

      <input
        type="file"
        hidden
        ref={csvRef}
        onChange={(e) => {
          setCsvFile(e.target.files[0]);
        }}
      />

      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Examination Dates</p>
            </div>
            <div className="flex justify-end items-center w-full gap-x-6">
              <div className="float-right">
                <ExcelExportBtn
                  data={[]}
                  fileName={'student_CSV.csv'}
                  text={'Download CSV Template'}
                  width={'w-40'}
                  headers={csvTemplate}
                />
              </div>
              <DropDown
                data={['All', 'Pre - Primary', 'Primary', 'Middle', 'Secondary', 'Senior - Secondary']}
                width={'w-1/4'}
                title={'Sort By'}
                handleItemClick={(x) => {
                  setFilter(x);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div
              className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
              style={{ width: '96%' }}
            >
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Section</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class Teacher</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Upload </div>
                  <div className="w-1/5 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '50vh' }}>
                {false ? (
                  <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                ) : (
                  _classes.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-3 px-4 text-xs font-medium items-center ${
                          idx !== _classes.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-textDark">{x.class}</div>
                        <div className="w-1/5 px-2 text-textDark">{x.section}</div>
                        <div className="w-1/5 px-2 text-textDark">{x.headTeacher || 'Not Assigned'}</div>
                        <div className="w-1/5 px-2">
                          {selectedClassForCSV &&
                          x.sectionId === selectedClassForCSV.sectionId &&
                          (postDatesResponse.isLoading || deleteDatesResponse.isLoading) ? (
                            <img src="assets/img/loader.svg" className="w-12 m-auto " alt="" />
                          ) : (
                            <button
                              type="submit"
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() => {
                                if (x.examDates) handleDelete2(x);
                                else handleFileSelect(x);
                              }}
                            >
                              {x.examDates ? 'Remove' : 'Upload CSV'}
                            </button>
                          )}
                        </div>
                        <div className="w-1/5 px-2">
                          {x.examDates ? (
                            <button
                              type="submit"
                              className="border border-primary w-9/12 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center text-xs"
                              onClick={() => selectClassForDates(x)}
                            >
                              View
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
