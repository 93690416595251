import React from 'react';
import { useLocation } from 'react-router-dom';
import Dashboard from '../Admin/Dashboard';
import Academics from '../Owner/Academics';
import Attendance from '../Admin/AttendanceReport';
import Staff from '../Admin/Staff';
import ManageLeaves from '../Admin/ManageLeaves';
import IncidentReport from '../Admin/IncidentReport';
import Meeting from '../Owner/Meeting';
import StudentReport from '../Admin/StudentReport';
import TaskBoard from '../Owner/TaskBoard';
import Notice from '../Owner/Notice';
import Feedback from '../Parents/Feedback';
import Admissions from './Admissions';
import MapResponsibility from './MapResponsibility';
import ReleaseResult from './ReleaseResult';
import Transport from '../Admin/Transport';
import AdmissionIncharge from '../Teacher/AdditionalResponsibilities/AdmissionIncharge';
import TimetableIncharge from '../Teacher/AdditionalResponsibilities/TimetableIncharge';
import ExaminationIncharge from '../Teacher/AdditionalResponsibilities/ExaminationIncharge';
import MentalHealth from '../Teacher/MentalHealth';
import AI from '../Teacher/AI';
import EdTech from '../Teacher/EdTech';
import Students from '../Owner/Students';
import InventoryIncharge from '../Teacher/AdditionalResponsibilities/InventoryIncharge';
import AcademicCalender from './AcademicCalender';

const Principal = () => {
  const location = useLocation();

  return (
    <div>
      {location.search === '' && <Dashboard role={'principal'} />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('Academics') && <Academics />}
      {location.search.includes('Attendance') && <Attendance role={'principal'} />}
      {location.search.includes('Staff') && <Staff />}
      {location.search.includes('Leaves') && <ManageLeaves />}
      {location.search.includes('Incident') && <IncidentReport />}
      {location.search.includes('MeetingRequest') && <Meeting />}
      {location.search.includes('StudentReport') && <StudentReport />}
      {location.search.includes('TaskBoard') && <TaskBoard />}
      {location.search.includes('ShareNotice') && <Notice />}
      {location.search.includes('Feedback') && <Feedback role="Principal" />}
      {location.search.includes('Admissions') && <Admissions />}
      {location.search.includes('additional-responsibilities') && <MapResponsibility />}
      {location.search.includes('Result') && <ReleaseResult />}
      {location.search.includes('Students') && <Students />}

      {location.search.includes('TransportIncharge') && <Transport />}
      {location.search.includes('TimetableIncharge') && <TimetableIncharge />}
      {location.search.includes('AdmissionIncharge') && <AdmissionIncharge />}
      {location.search.includes('ExaminationIncharge') && <ExaminationIncharge />}
      {location.search.includes('InventoryIncharge') && <InventoryIncharge />}

      {location.search.includes('MentalHealth') && <MentalHealth />}
      {location.search.includes('EdTech') && <EdTech />}
      {location.search.includes('TextExtraction') && <AI />}
    </div>
  );
};
export default Principal;
