import React, { useState, useEffect } from 'react';
import Apply from '../../../components/Finance/Leave/Apply';
import History from '../../../components/Finance/Leave/History';
import Deatils from '../../../components/Finance/Leave/Deatils';
import Balance from '../../../components/Finance/Leave/Balance';
import StaffLeaves from '../../../components/Admin/Leaves/StaffLeaves';

import useApi from '../../../api/useApi';
import { LEAVE_TYPES, STAFF_LEAVE_HISTORY, STAFF_USER_LEAVE_HISTORY } from '../../../api/urls/attendance';

function Dashboard({ role }) {
  const [details, setDetails] = useState();
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [teachingStaffHistory, setTeachingStaffHistory] = useState([]);

  const [leaveTypesState, getLeaveTypes] = useApi();
  const [teachingStaffState, getTeachingStaffLeaves] = useApi();

  const [{ isLoading, isError, data }, getLeaveHistory] = useApi();
  const [leaveHistory, setLeaveHistory] = useState([]);

  useEffect(() => {
    getLeaveHistory(STAFF_USER_LEAVE_HISTORY, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setLeaveHistory(data);
    }
  }, [isLoading, isError, data]);

  useEffect(() => {
    getLeaveTypes(LEAVE_TYPES, 'GET');
    // if (role !== 'admin') 
    getTeachingStaffLeaves(STAFF_LEAVE_HISTORY, 'GET');
  }, []);

  useEffect(() => {
    if (!leaveTypesState.isLoading && !leaveTypesState.isError && leaveTypesState.data) {
      setLeaveTypes(leaveTypesState.data);
    }
  }, [leaveTypesState]);

  useEffect(() => {
    if (!teachingStaffState.isLoading && !teachingStaffState.isError && teachingStaffState.data) {
      setTeachingStaffHistory(teachingStaffState.data);
    }
  }, [teachingStaffState]);

  const refreshList = () => {
    getLeaveHistory(STAFF_USER_LEAVE_HISTORY, 'GET');
  };

  const onClose = (refresh) => {
    if (refresh) {
      // if (role !== 'admin') 
      // getTeachingStaffLeaves(STAFF_LEAVE_HISTORY, 'GET');
    }
    setDetails(null);
  };

  return (
    <div className="w-full md:w-4/5 pb-10">
      {details && <Deatils role={'admin'} details={details} setDetails={setDetails} onClose={onClose} />}
      {role !== 'admin' && (
        <>
          <StaffLeaves title={'Teaching Staff'} openDetails={setDetails} history={teachingStaffHistory} />
          <StaffLeaves title={'Non-Teaching Staff'} openDetails={setDetails} history={[]} />
        </>
      )}
      <Apply refreshList={refreshList} />
      <Balance leaveTypes={leaveTypes} />
      <History openDetails={setDetails} leaveHistory={leaveHistory} />
    </div>
  );
}

export default Dashboard;
