import React, { useState } from 'react';
import HolidayMapping from './HolidayMapping';
import ExamQuizMapping from './ExamQuizMapping';
import Preview from './Preview';
import OtherHoliday from './OtherHoliday';
import AddEvent from './AddEvent';

const CalenderTabs = ({ calanderId, showCalander }) => {
  const [step, setStep] = useState(0);
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [openAddEvent, setOpenAddEvent] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [otherHolidays, setOtherHolidays] = useState([]);
  const [options, setOptions] = useState([]);

  const handleAddOther = (date) => {
    setOpenAddHoliday(date);
  };
  const handleCloseOther = () => {
    setOpenAddHoliday(false);
  };

  const handleAddEvent = () => {
    setOpenAddEvent(true);
  };
  const handleCloseEvent = () => {
    setOpenAddEvent(false);
  };

  const handleAddOtherHoliday = (m) => {
    setOtherHolidays((prev) => [...prev, m]);
    handleAddHoliday(m);
  };

  const handleAddHoliday = (h) => {
    const old = allEvents;
    const index = old.findIndex((m) => m.name === h.name && m.date === h.date);
    if (index > -1) {
      old.splice(index, 1);
      setAllEvents([...old]);
    } else {
      setAllEvents((prev) => [...prev, h]);
    }
  };

  return (
    <div>
      {openAddHoliday && (
        <OtherHoliday
          date={openAddHoliday}
          handleCloseOther={handleCloseOther}
          handleAddOtherHoliday={handleAddOtherHoliday}
        />
      )}

      {openAddEvent && <AddEvent handleCloseEvent={handleCloseEvent} />}

      <div className="pb-3 pt-2 px-8 relative grid grid-cols-2 md:grid-cols-3 gap-x-8 border-b border-borderYellow">
        <button
          // onClick={() => setStep(0)}
          className={`py-2 w-full cursor-pointer rounded-full text-xs ${
            step === 0 ? 'bg-primary text-white' : 'bg-white text-black'
          }`}
        >
          Holiday Mapping
        </button>
        <button
          // onClick={() => setStep(1)}
          className={`py-2 w-full cursor-pointer rounded-full text-xs ${
            step === 1 ? 'bg-primary text-white' : 'bg-white text-black'
          }`}
        >
          Exam and Quiz Mapping
        </button>
        <button
          // onClick={() => setStep(2)}
          className={`py-2 w-full cursor-pointer rounded-full text-xs ${
            step === 2 ? 'bg-primary text-white' : 'bg-white text-black'
          }`}
        >
          Publish
        </button>
      </div>

      <div>
        {step === 0 ? (
          <HolidayMapping
            handleAddOther={handleAddOther}
            moveNext={() => setStep(1)}
            allEvents={allEvents}
            handleAddHoliday={handleAddHoliday}
            otherHolidays={otherHolidays}
            calanderId={calanderId}
          />
        ) : step === 1 ? (
          <ExamQuizMapping
            moveNext={() => setStep(2)}
            movePrevious={() => setStep(1)}
            calanderId={calanderId}
            setOptions={setOptions}
          />
        ) : (
          <Preview
            movePrevious={() => setStep(2)}
            openAddEvent={handleAddEvent}
            options={options}
            calanderId={calanderId}
            showCalander={showCalander}
          />
        )}
      </div>
    </div>
  );
};

export default CalenderTabs;
