import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DropDown from '../../../components/UI/DropDown';
import useApi from '../../../api/useApi';
import { STUDENT_LECTURES, SCHEDULE_STUDENT } from '../../../api/urls/notes';
import { useStateValue } from '../../../ContextApi/StateProvider';
import { STUDENT_SUBJECT } from '../../../api/urls/instituteManagement';

function LectureSummary({ role }) {
  const [selected, select] = useState();
  const [selectedSubject, selectSubject] = useState();
  const [selectedLesson, selectLesson] = useState();

  const [{ user }, _] = useStateValue();

  const [lectures, getAllLectures] = useApi();
  const [subjects, getStudentSubjects] = useApi();
  const [scheduleRes, getSchedule] = useApi();

  useEffect(() => {
    getStudentSubjects(STUDENT_SUBJECT, 'GET');
  }, []);

  useEffect(() => {
    if (user) {
      if (role === 'student') {
        const studentInfo = user?.user?.currentlyMapped?.classInfo;
        getSchedule(SCHEDULE_STUDENT(studentInfo?.classId, studentInfo?.sectionId), 'GET');
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo;
        getSchedule(SCHEDULE_STUDENT(childInfo?.classId, childInfo?.sectionId), 'GET');
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      !subjects.isLoading &&
      !subjects.isError &&
      subjects.data &&
      subjects.data.subjects &&
      subjects.data.subjects.length > 0
    ) {
      selectSubject(subjects.data?.subjects[0].id);
      selectLesson(subjects.data?.subjects[0]?.course?.course[0]?.lessonNo);
    }
  }, [subjects]);

  useEffect(() => {
    if (user && selectedSubject) {
      if (role === 'student') {
        const studentInfo = user?.user?.currentlyMapped?.classInfo;
        getAllLectures(STUDENT_LECTURES(studentInfo?.classId, studentInfo?.sectionId, selectedSubject), 'GET');
      } else {
        const childInfo = user?.Responsbility[0]?.childInfo?.currentlyMapped?.classInfo;
        getAllLectures(STUDENT_LECTURES(childInfo?.classId, childInfo?.sectionId, selectedSubject), 'GET');
      }
    }
  }, [user, selectedSubject]);

  const renderSchedule = (schedule) => {
    if (!schedule) return null;

    return schedule
      .split('\n')
      .slice(2)
      .map((line, index) => {
        if (line.replaceAll('*', '').trim().includes('Topic: ')) {
          const topic = line.replaceAll('*', '').split('Topic: ')[1]?.split(',')[0] || '';
          return (
            <div key={index} className="flex justify-between gap-x-4 text-primary">
              <span className="font-semibold">{line.replaceAll('*', '')}</span>
              {/* {topic && (
                <button
                  onClick={() => generateNotes(topic)}
                  className={`border border-primary w-36 rounded-3xl text-xs py-1.5 font-medium focus:outline-none bg-white text-primary ${
                    isLoading && topic === selectedTopic ? 'opacity-50' : 'opacity-100'
                  }`}
                  disabled={isLoading}
                >
                  {isLoading && topic === selectedTopic ? 'Generating...' : 'Generate Notes'}
                </button>
              )} */}
            </div>
          );
        }

        if (line.trim().replaceAll('*', '').includes('Day')) {
          const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2">
              <p className="font-medium text-gray-400">
                <i>{day}</i>
              </p>
            </div>
          );
        }

        if (line.trim().replaceAll('*', '').includes('Week')) {
          const day = line.trim().replaceAll('*', '').replaceAll('#', '');
          return (
            <div key={index} className="my-2 font-semibold text-lg">
              <p className="">{day}</p>
            </div>
          );
        }

        return <div key={index}>{line}</div>;
      });
  };

  const subject = subjects?.data?.subjects?.find((subject) => subject.id === selectedSubject);
  const lesson = subject?.course?.course?.find((course) => course.lessonNo === selectedLesson);

  const summary = lectures?.data?.filter((x) => x.lesson === lesson?.lessonName) || [];
  return (
    <>
      {selected && (
        <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-11/12 md:w-5/12 text-sm m-auto mt-20 rounded-2xl relative">
            <div className="flex justify-between border-b border-borderYellow items-center">
              <span className="font-bold px-6 py-4">Lecture Detail - {subject.name}</span>
              <div className="flex gap-x-4 items-center pr-2">
                {/* <a
                  className="border float-right px-10 rounded-3xl text-xs font-bold focus:outline-none text-center py-2 bg-primary text-white"
                  href={selected.notes.files[0].location}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Export Data
                </a> */}

                <img
                  src="assets/img/Close.svg"
                  className="h-3 mr-4 cursor-pointer"
                  alt=""
                  onClick={() => select(null)}
                />
              </div>
            </div>

            <div className="p-6 m-auto" autoComplete="off">
              <div className="w-full relative mb-6">
                <span className="font-bold">Title</span>
                <p className="text-sm text-textDark mt-2">{selected?.title}</p>
              </div>

              <div className="mt-2 mb-6 w-full relative ">
                <span className="font-bold">Description</span>
                <p className="text-sm text-textDark mt-2">{selected?.summary}</p>
              </div>

              <div className="mx-4 w-full flex">
                {selected?.notes && (
                  <a
                    className="border border-primary ml-auto w-40  mr-6 rounded-3xl text-xs focus:outline-none text-center py-2 bg-primary text-white"
                    href={selected?.notes?.files[0]?.location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Notes
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {scheduleRes.isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
      ) : scheduleRes?.data && Array.isArray(scheduleRes?.data) && scheduleRes?.data?.length > 0 ? (
        scheduleRes?.data?.map((x) => (
          <div className="w-full md:w-4/5">
            <div className="w-full bg-white rounded-xl h-auto mb-5">
              <div className="items-center px-6 py-3 border-b border-borderYellow">
                <div className="flex flex-col md:flex-row justify-between md:items-center">
                  <div className="font-bold">
                    <p>{`${x?.subjectId?.subjectName}-${x?.subjectId?.subjectCode}`}</p>
                  </div>
                </div>
              </div>
              <div className="flex w-full ">
                <div className="flex flex-wrap w-full overflow-auto px-6 customScrollBar" style={{ maxHeight: '50vh' }}>
                  <div className="w-full py-4 overflow-auto">{renderSchedule(x?.content)}</div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
      )}

      {/* <div className="w-full md:w-4/5">
        <div className="w-full bg-white rounded-xl h-auto mb-5">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex flex-col md:flex-row justify-between md:items-center">
              <div className="font-bold">
                <p>{`${subject?.code} ${subject?.name} ${lesson?.lessonName || ''}`}</p>
              </div>

              <div className="gap-x-4 flex">
                <DropDown
                  type={'obj'}
                  field={'name'}
                  data={subjects && subjects.data && subjects.data.subjects}
                  width={'w-52'}
                  title={`${subject?.code} ${subject?.name}` || 'Select'}
                  handleItemClick={(x) => {
                    selectSubject(x.id);
                  }}
                  key={selectedSubject}
                />

                <DropDown
                  data={subject?.course?.course.map((x) => x.lessonNo)}
                  width={'w-40'}
                  title={selectedLesson || 'Select Lesson'}
                  handleItemClick={(x) => {
                    selectLesson(x);
                  }}
                  key={selectedLesson}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full ">
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700"
                style={{ width: '96%' }}
              >
                <div className="w-full  bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Lecture No</div>
                    <div className="w-3/6 border-r-2 py-2 px-2">Title</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Notes</div>
                    <div className="w-1/6 py-2 px-2">Summary</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '60vh' }}>
                  {summary.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
                  ) : summary && summary.length > 0 ? (
                    summary?.map((x, idx) => (
                      <div
                        className={`justify-center flex w-full relative text-left  text-xs items-center ${
                          idx !== summary.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="justify-center flex w-full relative text-left py-3.5 px-4 text-xs font-medium items-center">
                          <div className="w-1/6 text-tableRowText px-2">{idx + 1 < 10 ? '0' + (idx + 1) : idx + 1}</div>
                          <div className="w-3/6 text-tableRowText px-2">{x?.title}</div>
                          <div className="w-1/6 text-tableRowText px-2">
                            {x.notes && (
                              <a
                                className="border border-primary  py-2 rounded-3xl text-primary font-medium  focus:outline-none flex justify-center text-center items-center w-11/12"
                                download
                                href={x.notes.files[0].location}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </a>
                            )}
                          </div>
                          <div className="w-1/6 text-tableRowText px-2">
                            <button
                              type="submit"
                              className="border border-primary  py-2 rounded-3xl text-primary font-medium  focus:outline-none flex justify-center text-center items-center w-11/12"
                              onClick={() => select(x)}
                            >
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default LectureSummary;
