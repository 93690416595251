import React from 'react';
import { useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import CourseHandout from './Course Handout';
import Attendance from './Attendance';
import Lectures from './Lectures';
import LiveSessions from './Live Sessions';
import AssignmentsAndTest from './Assignments and Test';
import Remarks from './Remarks';
import Reports from './Reports';
import Meeting from '../Owner/Meeting';
import Leave from '../Finance/Leave';
import TaskBoard from '../Owner/TaskBoard';
import Feedback from '../Parents/Feedback';
import EventsTimetable from './EventsTimetable';
import Marks from './Marks';
import Transport from '../Admin/Transport';
import AdmissionIncharge from './AdditionalResponsibilities/AdmissionIncharge';
import ClassTeacher from './AdditionalResponsibilities/ClassTeacher';
import TimetableIncharge from './AdditionalResponsibilities/TimetableIncharge';
import ExaminationIncharge from './AdditionalResponsibilities/ExaminationIncharge';
import useApi from '../../api/useApi';
import { TEACHER_SUBJECTS } from '../../api/urls/timeTable';
import HomeWork from './HomeWork';
import MentalHealth from './MentalHealth';
import EdTech from './EdTech';
import AI from './AI';
import InventoryIncharge from './AdditionalResponsibilities/InventoryIncharge';
import Syllabus from './Syllabus';
import AcademicCalender from '../Principal/AcademicCalender';

const Teacher = () => {
  const location = useLocation();

  const [data, getData] = useApi();

  React.useEffect(() => {
    getData(TEACHER_SUBJECTS, 'GET');
  }, []);

  const refreshList = () => {
    getData(TEACHER_SUBJECTS, 'GET');
  };

  return (
    <div>
      {location.search === '' && <Dashboard />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('CourseHandout') && (
        <CourseHandout subjects={Array.isArray(data?.data) ? data?.data : []} refreshList={refreshList} />
      )}
      {location.search.includes('Leave') && <Leave role={'Teacher'} />}
      {location.search.includes('Syllabus') && <Syllabus subjects={Array.isArray(data?.data) ? data?.data : []} />}
      {location.search.includes('Attendance') && !location.search.includes('Leave') && <Attendance />}
      {location.search.includes('Lectures') && <Lectures subjects={Array.isArray(data?.data) ? data?.data : []} />}
      {location.search.includes('LiveSessions') && (
        <LiveSessions subjects={Array.isArray(data?.data) ? data?.data : []} />
      )}
      {location.search.includes('AssignmentsandTest') && (
        <AssignmentsAndTest subjects={Array.isArray(data?.data) ? data?.data : []} />
      )}
      {location.search.includes('Marks') && <Marks subjects={Array.isArray(data?.data) ? data?.data : []} />}
      {location.search.includes('Events/Timetable') && <EventsTimetable />}
      {location.search.includes('Remarks') && <Remarks />}
      {location.search.includes('ReportIncident') && <Reports />}
      {location.search.includes('Meeting') && (
        <Meeting role={'Teacher'} subjects={Array.isArray(data?.data) ? data?.data : []} />
      )}
      {location.search.includes('Feedback') && <Feedback role="Teacher" />}
      {location.search.includes('Task') && <TaskBoard />}
      {location.search.includes('ClassTeacher') && <ClassTeacher />}
      {location.search.includes('TransportIncharge') && <Transport />}
      {location.search.includes('TimetableIncharge') && <TimetableIncharge />}
      {location.search.includes('AdmissionIncharge') && <AdmissionIncharge />}
      {location.search.includes('ExaminationIncharge') && <ExaminationIncharge />}
      {location.search.includes('InventoryIncharge') && <InventoryIncharge />}
      {location.search.includes('HomeWork') && <HomeWork subjects={Array.isArray(data?.data) ? data?.data : []} />}
      {location.search.includes('MentalHealth') && <MentalHealth />}
      {location.search.includes('EdTech') && <EdTech />}
      {location.search.includes('TextExtraction') && <AI />}
    </div>
  );
};
export default Teacher;
