import React, { useState, useEffect } from 'react';
import UploadModal from './UploadModal';
import { SYLLABUS_GET } from '../../../api/urls/timeTable';
import useApi from '../../../api/useApi';
import { utils, writeFile } from 'xlsx';
import Schedule from './Schedule';
import Notes from './Notes';
import axios from 'axios';

const Syllabus = ({ subjects }) => {
  const [open, setOpen] = useState();

  const [printData, setPrintData] = useState();
  const [selected, select] = useState();
  const [notes, setNotes] = useState();
  const [schedule, setSchedule] = useState();
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState();

  const [{ isLoading, data, isError }, getSyllabus] = useApi();

  const handleOpenDownload = (exam, _class, section, subjectName, subjectId) => {
    setOpen({ exam, _class, section, subjectName, subjectId });
  };

  useEffect(() => {
    getSyllabus(SYLLABUS_GET, 'GET');
  }, []);

  const handleClose = (refresh) => {
    if (refresh) {
      getSyllabus(SYLLABUS_GET, 'GET');
    }
    setOpen(null);
  };

  const generateSchedule = async (type) => {
    const body = JSON.stringify(type === 'image' ? extractedText : excelData);
    const prompt = `Please schedule this course syllabus over 30 days according to level of difficulty with 2 class tests and 1 assignment. Generate in format week\nDay\nTopic\nsubtopics:\n\n${body}`;

    setLoading(true);
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: prompt,
                },
              ],
            },
          ],
          max_tokens: 2000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer sk-proj-2GYEsmjjf7T48hED44WGT3BlbkFJbmWXUqIup8gjYP9xjieX',
          },
        }
      );
      handleClose();
      setSchedule(response?.data?.choices?.[0]?.message?.content);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const checkIfUploaded = (subjectId, exam) => {
    const x = data?.syllabus?.find((c) => c.subjectId == subjectId && c.type == exam)?.syllabus;
    return x;
  };

  function ExportData(data, _filename) {
    if (data.length > 0 && typeof data[0] == 'string') {
      setPrintData(data);
      setTimeout(() => {
        window.print();
      }, 600);
    } else {
      const filename = _filename + '.xlsx';

      var ws = utils.json_to_sheet(data);
      var wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'People');
      writeFile(wb, filename);
    }
  }

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {open && (
        <UploadModal
          data={open}
          onClose={handleClose}
          setSchedule={setSchedule}
          loading={loading}
          generateSchedule={generateSchedule}
          setExcelData={setExcelData}
          excelData={excelData}
        />
      )}

      {notes && (
        <Notes
          notes={notes}
          onClose={() => {
            setNotes(null);
          }}
        />
      )}

      {/* Print UI if data is simple string*/}
      {!notes && (
        <div className="flex flex-col overflow-auto px-10" id="section-to-print">
          <div className="flex flex-col mt-4 mb-4">
            <h2 className="text-black font-medium mb-2">{`Syllabus`}</h2>
            <h3 className="text-black font-medium mb-5">{`Class: ${selected?.class?.name}-${selected?.section?.name}, Exam: ${selected?.exam} Subject: ${selected?.subjectName}`}</h3>
            <p className="">{printData}</p>
          </div>
        </div>
      )}

      <div className="w-full bg-white rounded-xl h-auto">
        <div className="items-center px-6 py-4 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Academic Syllabus</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4">
          <div className="w-full py-4 overflow-auto">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow min-w-700" style={{ width: '96%' }}>
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Subject Code</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Class</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Unit 1</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Unit 2</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Term 1</div>
                  <div className="w-1/5 border-r-2 py-2 px-2">Unit 3</div>
                  <div className="w-1/5 py-2 px-2">Term 2</div>
                </div>
              </div>
              <div>
                {Array.isArray(subjects) && subjects.length > 0 ? (
                  subjects.map((x, idx) => (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-2.5 px-4 text-xs items-center ${
                          idx !== subjects.length - 1 && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 text-tableRowText">
                          {x?.subjectName.split(' ').length > 0 ? x?.subjectName.split(' ')[1] : x?.subjectName}
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.subjectName.split(' ')[0]}</div>
                        <div className="w-1/5 px-2 text-tableRowText">{x?.class?.name + '-' + x?.section.name}</div>
                        <div className="w-1/5 px-2">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u1') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u1');
                              select({ ...x, exam: 'u1' });

                              _data
                                ? ExportData(_data, 'u1')
                                : handleOpenDownload(
                                    'u1',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u1') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u2') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u2');
                              select({ ...x, exam: 'u2' });

                              _data
                                ? ExportData(_data, 'u2')
                                : handleOpenDownload(
                                    'u2',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u2') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 't1') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 't1');
                              select({ ...x, exam: 't1' });

                              _data
                                ? ExportData(_data, 't1')
                                : handleOpenDownload(
                                    't1',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 't1') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 'u3') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 'u3');
                              select({ ...x, exam: 'u3' });

                              _data
                                ? ExportData(_data, 'u3')
                                : handleOpenDownload(
                                    'u3',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 'u3') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                        <div className="w-1/5 px-2 text-tableRowText text-center">
                          <button
                            className={`border ${
                              checkIfUploaded(x?.subjectId, 't2') ? 'text-primary bg-white' : 'text-white bg-primary'
                            } border-primary px-2 text-xs py-2 rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center`}
                            onClick={() => {
                              const _data = checkIfUploaded(x?.subjectId, 't2');
                              select({ ...x, exam: 't2' });

                              _data
                                ? ExportData(_data, 't2')
                                : handleOpenDownload(
                                    't2',
                                    x?.class,
                                    x?.section,
                                    x?.subjectName.split(' ').length > 0
                                      ? x?.subjectName.split(' ')[1]
                                      : x?.subjectName,
                                    x?.subjectId
                                  );
                            }}
                          >
                            {checkIfUploaded(x?.subjectId, 't2') ? 'Download' : 'Upload'}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {schedule && (
        <Schedule
          schedule={schedule}
          onClose={() => {
            setSchedule(null);
          }}
          setNotes={setNotes}
          data={selected}
          generateSchedule={generateSchedule}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Syllabus;
