import { NOTES } from './baseUrls';

let year = sessionStorage.getItem('year');
if (!year) {
  if (new Date().getMonth() < 3) year = new Date().getFullYear() - 1;
  else year = new Date().getFullYear();
}

export const GET_ALL_LECTURES = `${NOTES}/institute/getAllLeacture?year=${year}`;

export const CREATE_SUMMARY = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/summary?year=${year}`;

export const UPDATE_SUMMARY = (classId, sectionId, subjectId, summaryId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/summary/${summaryId}?year=${year}`;

export const UPLOAD_NOTES = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}?year=${year}`;

export const GET_ALL_LIVE_LECTURES = `${NOTES}/institute/getAllLiveLecture?year=${year}`;

export const CREATE_LECTURE_LINK = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/live?year=${year}`;

export const UPDATE_LECTURE_LINK = (classId, sectionId, subjectId, id) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/live/${id}?year=${year}`;

export const STUDENT_LECTURES = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/summary?year=${year}`;

export const STUDENT_UPCOMING_LIVE_SESSIONS = (classId, sectionId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/live?year=${year}`;

export const CREATE_ASSIGNMENT = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/assignment?year=${year}`;

export const GET_TEACHER_ASSIGNMENT = `${NOTES}/getAllAssignment?year=${year}`;

export const ASSIGNMENT_MARKS = (classId, sectionId, subjectId, assignmentId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/assignment/name/${assignmentId}?year=${year}`;

export const UPLOAD_ASSIGNMENT_MARKS = (assignmentId) => `${NOTES}/assignment/${assignmentId}?year=${year}`;

export const STUDENT_ASSIGNMENT = `${NOTES}/institute/getAssignmentList?year=${year}`;

export const DELETE_ASSIGNMENT = (id) => `${NOTES}/institute/assignment/${id}?year=${year}`;

export const UPLOAD_ASSIGNMENT = (classId, sectionId, subjectId, assignmentId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/assignment/${assignmentId}?year=${year}`;

export const STUDENT_ASSIGNMENT_MARKS = `${NOTES}/assignment/student/marks?year=${year}`;

export const UPLOAD_HOMEWORK = `${NOTES}/institute/createHomeWork?year=${year}`;

export const GET_UPLOADED_HOMEWORK = `${NOTES}/institute/getTeacherHomeWorkList?year=${year}`;

export const STUDENT_HOMEWORK = `${NOTES}/institute/getHomeWork?year=${year}`;

export const DELETE_HOMEWORK = (id) => `${NOTES}/institute/homework/${id}?year=${year}`;

export const SCHEDULE = (classId, sectionId, subjectId) =>
  `${NOTES}/institute/class/${classId}/section/${sectionId}/subject/${subjectId}/schedule?year=${year}`;

export const GET_SCHEDULE = `${NOTES}/institute/schedule?year=${year}`;

export const SCHEDULE_STUDENT = (classId, sectionId) =>
  `${NOTES}/institute/student/class/${classId}/section/${sectionId}/schedule?year=${year}`;
