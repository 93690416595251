import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useApi from '../../../api/useApi';
import { CREATE_USER, UPDATE_USER } from '../../../api/urls/user';
import DropDown from '../../UI/DropDown';
import { isValidEmail, isValidPhone } from '../../../utils';

const StaffProfile = ({ handleClose, staffRole, selectedStaff, refreshList }) => {
  const [step, setStep] = useState(0);
  const [isEdit, setEdit] = useState(!selectedStaff);

  const formDataModel = {
    email_id: '',
    full_name: '',
    gender: '',
    dateOfBirth: '',
    maritalStatus: '',
    // aadhar: '',
    roleType: staffRole === 'teaching' ? 'te' : 'nt',
    role: staffRole,
    age: '',
  };

  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };

  const experienceModel = {
    title: '',
    type: '',
    companyName: '',
    location: '',
    startDate: '',
    endDate: '',
  };

  const educationModel = {
    university: '',
    degree: '',
    fieldOfStudy: '',
    grade: '',
    startDate: '',
    endDate: '',
  };

  const jobProfileModel = {
    title: '',
    supervisor: '',
    email: '',
    contact: '',
    startDate: '',
    // salary: '',
  };

  const bankAccountModel = {
    bank: '',
    accountNo: '',
    ifsc: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [caddressState, setcAddressState] = useState(addressModel);
  const [experienceState, setexperienceState] = useState([experienceModel]);
  const [educationState, setEducationState] = useState([educationModel]);
  const [jobProfileState, setJobProfileState] = useState([jobProfileModel]);
  const [bankState, setBankState] = useState(bankAccountModel);
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState('/assets/img/user_placeholder.svg');

  const [experienceList, setExperienceList] = useState([0]);
  const [educationList, setEducationList] = useState([0]);
  const [jobProfileList, setJobProfileList] = useState([0]);

  const imgRef = useRef();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  useEffect(() => {
    if (selectedStaff) {
      setFormData({ ...selectedStaff, age: getAge(new Date(selectedStaff.dateOfBirth)) });
      setAddressState(selectedStaff.address);
      setcAddressState(selectedStaff.caddress);
      setexperienceState(selectedStaff.experience);
      setEducationState(selectedStaff.education);
      setJobProfileState(selectedStaff.jobProfile);
      setBankState(selectedStaff.bankDetail);

      setEducationList(selectedStaff.education.map((x, idx) => idx));
      setExperienceList(selectedStaff.experience.map((x, idx) => idx));
      setJobProfileList(selectedStaff.jobProfile.map((x, idx) => idx));

      if (selectedStaff.fileArray.length > 0 && selectedStaff.fileArray[0].profileImg.location)
        setImage(selectedStaff.fileArray[0].profileImg.location);
    }
  }, [selectedStaff]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const handlecAddressInputChange = (e) => setcAddressState({ ...caddressState, [e.target.name]: e.target.value });

  const handleExperienceChange = (e, index) => {
    const x = experienceState;
    const y = {
      ...experienceState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setexperienceState([...x]);
  };

  const handleEducationChange = (e, index) => {
    const x = educationState;
    const y = {
      ...educationState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setEducationState([...x]);
  };

  const handleJobProfileChange = (e, index) => {
    const x = jobProfileState;
    const y = {
      ...jobProfileState[index],
      [e.target.name]: e.target.value,
    };
    x[index] = y;
    setJobProfileState([...x]);
  };

  const handlecBankInputChange = (e) => setBankState({ ...bankState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createStaff] = useApi();

  const handleSubmit = (e) => {
    e?.preventDefault();

    const formData2 = new FormData();

    formData2.append('address', JSON.stringify(addressState));
    formData2.append('caddress', JSON.stringify(caddressState));
    formData2.append('experience', JSON.stringify(experienceState));
    formData2.append('education', JSON.stringify(educationState));
    formData2.append('jobProfile', JSON.stringify(jobProfileState));
    formData2.append('bankDetail', JSON.stringify(bankState));
    // formData2.append('email_id', formData.email_id);
    formData2.append('full_name', formData.full_name);
    formData2.append('gender', formData.gender);
    formData2.append('dateOfBirth', formData.dateOfBirth);
    formData2.append('email_id', jobProfileState[0].email);
    formData2.append('phone', jobProfileState[0].contact);
    // formData2.append('aadhar', formData.aadhar);
    formData2.append('maritalStatus', formData.maritalStatus);
    formData2.append('roleType', formData.roleType);
    formData2.append('role', staffRole);
    formData2.append('profileImg', profileImg);
    if (selectedStaff) formData2.append('username', formData.username);
    // formData2.append('age', formData.age);

    if (selectedStaff) createStaff(UPDATE_USER(selectedStaff._id), 'PUT', formData2);
    else createStaff(CREATE_USER, 'POST', formData2);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      refreshList();
      alert('Success');
      if (selectedStaff) setEdit(false);
      else {
        handleClose();
        setFormData(formDataModel);
      }
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);

  const getAge = (date) => {
    console.log(date);
    const age = moment().diff(date, 'years', false);
    console.log(age);
    return age;
  };

  const goto1 = (e) => {
    e.preventDefault();
    let str = [];
    if (!formData?.full_name?.trim()) str.push('Name');
    if (!formData?.dateOfBirth) str.push('Date of birth');

    if (str.length > 0) {
      alert(`${str.join(', ')} are mandatory`);
      return;
    }

    if (selectedStaff) handleSubmit();
    else setStep(1);
  };

  const goto2 = (e) => {
    e.preventDefault();
    let str = [];
    if (!addressState?.hno?.trim()) str.push('Flat No. / House No');
    if (!addressState?.district?.trim()) str.push('District');
    if (!addressState?.city?.trim()) str.push('City');
    if (!addressState?.state?.trim()) str.push('State');
    if (!addressState?.pincode?.trim()) str.push('Pin COde');

    if (str.length > 0) {
      alert(`${str.join(', ')} are mandatory`);
      return;
    }

    if (selectedStaff) handleSubmit();
    else setStep(2);
  };

  const goto3 = (e) => {
    e?.preventDefault();
    if (selectedStaff) handleSubmit();
    else setStep(3);
  };

  const goto4 = (e) => {
    e?.preventDefault();
    if (selectedStaff) handleSubmit();
    else setStep(4);
  };

  const goto5 = (e) => {
    e.preventDefault();
    let str = [];
    jobProfileState.forEach((x, idx) => {
      let k = [];
      if (!x?.title?.trim()) k.push('Title');
      if (!x?.contact?.trim()) k.push('Contact');
      if (!x?.email?.trim()) k.push('Email');

      if (k.length > 0) {
        str.push(`${k.join(', ')} missing in Profile - ${idx + 1}`);
      }
    });

    if (str.length > 0) {
      alert(`${str.join(', ')}`);
      return;
    }

    if (selectedStaff) handleSubmit();
    else setStep(5);
  };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-11/12 md:w-4/5 pb-6 mx-auto h-auto mt-6 md:mt-16 bg-white rounded-2xl">
        <div className="text-sm block relative border-b border-borderYellow">
          <div className="flex justify-between items-center py-3">
            <div className="font-bold px-8">
              <p>{selectedStaff ? `${formData.full_name} (ROLEDin ID - ${formData.username}))` : 'Add Staff'}</p>
            </div>
            <div className="flex px-8 gap-x-6 items-center">
              {selectedStaff && (
                <button
                  onClick={() => setEdit((prev) => !prev)}
                  className={`py-2 rounded-full text-xs ${
                    isEdit ? 'bg-white text-tableRowText border border-borderGrey' : 'bg-primary text-white'
                  } w-28`}
                >
                  {isEdit ? 'Cancel' : 'Edit'}
                </button>
              )}
              <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
            </div>
          </div>
        </div>

        <div className="py-3 px-8 relative grid grid-cols-3 md:grid-cols-6 gap-x-2 border-b border-borderYellow">
          <button
            onClick={() => selectedStaff && !isEdit && setStep(0)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 0 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Personal Information
          </button>
          <button
            onClick={() => selectedStaff && !isEdit && setStep(1)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 1 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Address
          </button>
          <button
            onClick={() => selectedStaff && !isEdit && setStep(2)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 2 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Experience
          </button>
          <button
            onClick={() => selectedStaff && !isEdit && setStep(3)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 3 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Education
          </button>
          <button
            onClick={() => selectedStaff && !isEdit && setStep(4)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 4 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Job Profile
          </button>
          <button
            onClick={() => selectedStaff && !isEdit && setStep(5)}
            className={`py-2 w-full rounded-full ${
              selectedStaff && !isEdit ? 'cursor-pointer' : 'cursor-default'
            } text-xs ${step === 5 ? 'bg-primary text-white' : 'bg-white text-black'}`}
          >
            Bank Account
          </button>
        </div>

        {step === 0 ? (
          isEdit ? (
            <form className="px-8" onSubmit={goto1}>
              <div className="items-center py-4 ">
                <p className=" font-medium ">Staff Information</p>
              </div>

              <div className="flex flex-col md:flex-row w-full">
                <div>
                  <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                    {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                    <input
                      hidden
                      ref={imgRef}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => setProfileImg(e.target.files[0])}
                    />
                    <img
                      src="assets/img/camera.png"
                      className="w-10 h-10 absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2"
                      onClick={(e) => {
                        e.preventDefault();
                        imgRef.current.click();
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full md:pl-12">
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Full Name<span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      autoComplete="off"
                      value={formData.full_name}
                      name="full_name"
                      onChange={handleInputChange}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Age</label>
                    <input
                      type="text"
                      value={formData.age ? formData.age + ' years' : ''}
                      name="age"
                      disabled
                      onChange={handleInputChange}
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                    />
                  </div>
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Gender</label>
                    <DropDown
                      data={['Male', 'Female']}
                      handleItemClick={(x) => {
                        handleInputChange({ target: { value: x, name: 'gender' } });
                      }}
                      title={selectedStaff?.gender ? selectedStaff.gender : 'Select'}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2 text-textDark'}
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">
                      Date of Birth<span className="text-red-500 ml-1">*</span>
                    </label>

                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={formData.dateOfBirth && new Date(formData.dateOfBirth)}
                      onChange={(date) => setFormData({ ...formData, dateOfBirth: date, age: getAge(date) })}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      required
                    />
                    {/* )} */}
                  </div>
                  {/* <div className="my-2 w-full relative font-semibold ">
                  <label  className="text-textDark font-medium text-sm">
                    AADHAR<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    // placeholder="Eg-19 may-1992"
                    value={formData.aadhar}
                    name="aadhar"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div> */}
                  <div className="my-2 w-full relative font-semibold ">
                    <label className="text-textDark font-medium text-sm">Marital Status</label>
                    <DropDown
                      data={['Single', 'Married']}
                      handleItemClick={(x) => {
                        handleInputChange({ target: { value: x, name: 'maritalStatus' } });
                      }}
                      title={selectedStaff?.maritalStatus || 'Select'}
                      width={'w-full'}
                      otherClasses={'border-borderLight mt-2 text-textDark'}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-end">
                {selectedStaff && isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <button
                    className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                    type="submit"
                  >
                    {selectedStaff ? 'Save' : 'Next'}
                  </button>
                )}
              </div>
            </form>
          ) : (
            <div className="px-8">
              <div className="items-center py-4 ">
                <p className=" font-medium ">Staff Information</p>
              </div>

              <div className="flex flex-col md:flex-row w-full">
                <div>
                  <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                    {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full md:pl-12">
                  <div className="my-2 w-full relative">
                    <label className="text-textDark font-medium text-sm">Full Name</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {formData?.full_name || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative">
                    <label className="text-textDark font-medium text-sm">Age</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {formData?.age ? formData.age + ' years' : '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative">
                    <label className="text-textDark font-medium text-sm">Gender</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {formData?.gender || '--'}
                    </p>
                  </div>

                  <div className="my-2 w-full relative">
                    <label className="text-textDark font-medium text-sm">Date of Birth</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {moment(formData?.dateOfBirth).format('D-MM-YYYY') || '--'}
                    </p>
                  </div>
                  {/* <div className="my-2 w-full relative font-semibold ">
              <label  className="text-textDark font-medium text-sm">
                AADHAR<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                required
                // placeholder="Eg-19 may-1992"
                value={formData.aadhar}
                name="aadhar"
                onChange={handleInputChange}
                className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
              />
            </div> */}
                  <div className="my-2 w-full relative">
                    <label className="text-textDark font-medium text-sm">Marital Status</label>
                    <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                      {formData?.maritalStatus || '--'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-x-4 justify-end">
                <button
                  className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                  onClick={() => setStep(1)}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 1 ? (
          isEdit ? (
            <form onSubmit={goto2}>
              <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Flat No. / House No.<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    required
                    autoComplete="off"
                    value={addressState.hno}
                    name="hno"
                    onChange={handleAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <input
                    type="text"
                    value={addressState.society}
                    name="society"
                    onChange={handleAddressInputChange}
                    // placeholder="Eg-Amar Colony"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    District<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={addressState.district}
                    name="district"
                    onChange={handleAddressInputChange}
                    // placeholder="Eg-Jalandhar"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    City<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={addressState.city}
                    name="city"
                    onChange={handleAddressInputChange}
                    // placeholder="Eg-Jalandhar"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    State<span className="text-red-500 ml-1">*</span>
                  </label>
                  <select
                    type="text"
                    required
                    value={addressState.state}
                    name="state"
                    onChange={handleAddressInputChange}
                    // placeholder="Eg-Punjab"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">
                    Pin Code<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={addressState.pincode}
                    name="pincode"
                    onChange={handleAddressInputChange}
                    // placeholder="Eg-141401"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <input
                    type="text"
                    // placeholder="Eg-198"
                    value={caddressState.hno}
                    name="hno"
                    onChange={handlecAddressInputChange}
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <input
                    type="text"
                    value={caddressState.society}
                    name="society"
                    onChange={handlecAddressInputChange}
                    // placeholder="Eg-Amar Colony"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <input
                    type="text"
                    // placeholder="Eg-Jalandhar"
                    value={caddressState.district}
                    name="district"
                    onChange={handlecAddressInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <input
                    type="text"
                    value={caddressState.city}
                    name="city"
                    onChange={handlecAddressInputChange}
                    // placeholder="Eg-Jalandhar"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <select
                    type="text"
                    value={caddressState.state}
                    name="state"
                    onChange={handlecAddressInputChange}
                    // placeholder="Eg-Punjab"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  >
                    <option value="">Select</option>
                    <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <input
                    type="text"
                    value={caddressState.pincode}
                    name="pincode"
                    onChange={handlecAddressInputChange}
                    // placeholder="Eg-141401"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              {selectedStaff && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <div className="flex gap-x-4 justify-between px-8">
                  {isEdit && selectedStaff ? null : (
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                      onClick={() => {
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </button>
                  )}
                  <button
                    className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center ml-auto"
                    type="submit"
                  >
                    {selectedStaff ? 'Save' : 'Next'}
                  </button>
                </div>
              )}
            </form>
          ) : (
            <div>
              <p className=" font-bold text-black pt-4 px-8">Permanent Address</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full border-b border-borderYellow px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.hno || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.society || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.district || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.city || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.state || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {addressState?.pincode || '--'}
                  </p>
                </div>
              </div>

              <p className=" font-bold text-black pt-4 px-8">Correspondance Address</p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Flat No. / House No.</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.hno || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Colony / Society</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.society || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">District</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.district || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">City</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.city || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">State</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.state || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative">
                  <label className="text-textDark font-medium text-sm">Pin Code</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none">
                    {caddressState?.pincode || '--'}
                  </p>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )
        ) : null}

        {step === 2 ? (
          isEdit ? (
            <form onSubmit={goto3}>
              {experienceList.map((x, idx) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">{`Experience ${idx + 1}`}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Title</label>
                      <input
                        type="text"
                        // placeholder="Eg-198"
                        autoComplete="off"
                        value={experienceState[x].title}
                        name="title"
                        onChange={(e) => handleExperienceChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Employment Type</label>
                      <DropDown
                        data={['Select', 'Full Time', 'Part Time', 'Contract']}
                        handleItemClick={(z) => {
                          handleExperienceChange({ target: { value: z, name: 'type' } }, x);
                        }}
                        title={(selectedStaff?.experience.length > 0 && experienceState[x]?.type) || 'Select'}
                        width={'w-full'}
                        otherClasses={'border-borderLight mt-2 text-textDark'}
                      />
                    </div>
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Company Name</label>
                      <input
                        type="text"
                        // placeholder="Eg-Jalandhar"
                        value={experienceState[x].companyName}
                        name="companyName"
                        onChange={(e) => handleExperienceChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Location</label>
                      <input
                        type="text"
                        // placeholder="Eg-Jalandhar"
                        value={experienceState[x].location}
                        name="location"
                        onChange={(e) => handleExperienceChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('D MMMM YYYY')}
                      </p>
                    ) : ( */}
                      <DatePicker
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                        selected={experienceState[x].startDate && new Date(experienceState[x].startDate)}
                        onChange={(date) => handleExperienceChange({ target: { name: 'startDate', value: date } }, x)}
                        dateFormat={'dd-MM-yyyy'}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {/* )} */}
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">End Date</label>
                      {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('D MMMM YYYY')}
                      </p>
                    ) : ( */}
                      <DatePicker
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                        selected={experienceState[x].endDate && new Date(experienceState[x].endDate)}
                        onChange={(date) => handleExperienceChange({ target: { name: 'endDate', value: date } }, x)}
                        dateFormat={'dd-MM-yyyy'}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
              ))}

              {selectedStaff && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <div className="flex gap-x-4 justify-between px-8">
                    {isEdit && selectedStaff ? null : (
                      <button
                        className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={() => {
                          setStep((prev) => prev - 1);
                        }}
                      >
                        Back
                      </button>
                    )}

                    <div className="flex gap-x-4 ml-auto">
                      <button
                        className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          setExperienceList([...experienceList, experienceList.length]);
                          setexperienceState([...experienceState, experienceModel]);
                        }}
                      >
                        Add Section
                      </button>

                      <button
                        className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                        type="submit"
                      >
                        {selectedStaff ? 'Save' : 'Next'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
          ) : (
            <div>
              {experienceList.map((x, idx) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">{`Experience ${idx + 1}`}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Title</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].title || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Employment Type</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].type || '--'}
                      </p>
                    </div>
                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Company Name</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].companyName || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Location</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].location || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].startDate
                          ? moment(experienceState[x].startDate).format('DD-MM-YYYY')
                          : '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">End Date</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {experienceState[x].endDate ? moment(experienceState[x].endDate).format('DD-MM-YYYY') : '--'}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>

                <div className="flex gap-x-4">
                  <button
                    className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep(3);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )
        ) : null}

        {step === 3 ? (
          isEdit ? (
            <form onSubmit={goto4}>
              {educationList.map((x, idx) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">{`Education ${idx + 1}`}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">University</label>
                      <input
                        type="text"
                        // placeholder="Eg-198"
                        autoComplete="off"
                        value={educationState[x].university}
                        name="university"
                        onChange={(e) => handleEducationChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Degree</label>
                      <input
                        type="text"
                        // placeholder="Full Time / Part Time / Contract"
                        value={educationState[x].degree}
                        name="degree"
                        onChange={(e) => handleEducationChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Field of Study</label>
                      <input
                        type="text"
                        // placeholder="Eg-Jalandhar"
                        value={educationState[x].fieldOfStudy}
                        name="fieldOfStudy"
                        onChange={(e) => handleEducationChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Grade</label>
                      <input
                        type="text"
                        value={educationState[x].grade}
                        name="grade"
                        onChange={(e) => handleEducationChange(e, x)}
                        // placeholder="Eg-Jalandhar"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('D MMMM YYYY')}
                      </p>
                    ) : ( */}
                      <DatePicker
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                        selected={educationState[x].startDate && new Date(educationState[x].startDate)}
                        onChange={(date) => handleEducationChange({ target: { name: 'startDate', value: date } }, x)}
                        dateFormat={'dd-MM-yyyy'}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {/* )} */}
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">End Date</label>
                      {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('D MMMM YYYY')}
                      </p>
                    ) : ( */}
                      <DatePicker
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                        selected={educationState[x].endDate && new Date(educationState[x].endDate)}
                        onChange={(date) => handleEducationChange({ target: { name: 'endDate', value: date } }, x)}
                        dateFormat={'dd-MM-yyyy'}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>
              ))}
              {selectedStaff && isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <div className="flex gap-x-4 justify-between px-8">
                    {isEdit && selectedStaff ? null : (
                      <button
                        className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={() => {
                          setStep((prev) => prev - 1);
                        }}
                      >
                        Back
                      </button>
                    )}

                    <div className="flex gap-x-4 ml-auto">
                      <button
                        className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          setEducationList([...educationList, educationList.length]);
                          setEducationState([...educationState, educationModel]);
                        }}
                      >
                        Add Section
                      </button>

                      <button
                        className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                        type="submit"
                      >
                        {selectedStaff ? 'Save' : 'Next'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
          ) : (
            <div>
              {educationList.map((x, idx) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">{`Education ${idx + 1}`}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">University</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x]?.university || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Degree</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x]?.degree || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Field of Study</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x]?.fieldOfStudy || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Grade</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x]?.grade || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x].startDate ? moment(educationState[x].startDate).format('DD-MM-YYYY') : '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">End Date</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {educationState[x].endDate ? moment(educationState[x].endDate).format('DD-MM-YYYY') : '--'}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>

                <div className="flex gap-x-4">
                  <button
                    className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep(4);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )
        ) : null}

        {step === 4 ? (
          isEdit ? (
            <form onSubmit={goto5}>
              {jobProfileList.map((x) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">Job Profile</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">
                        Title<span className="text-red-500 ml-1">*</span>
                      </label>
                      {
                        staffRole === 'teaching' ? (
                          <input
                            type="text"
                            required
                            // placeholder="Eg-198"
                            autoComplete="off"
                            value={jobProfileState[x].title}
                            name="title"
                            onChange={(e) => handleJobProfileChange(e, x)}
                            className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                          />
                        ) : (
                          <DropDown
                            data={['Accountant', 'Driver', 'Peon', 'Helper', 'Lab Assistant', 'Gardener']}
                            handleItemClick={(z) => {
                              handleJobProfileChange({ target: { value: z, name: 'title' } }, x);
                            }}
                            title={jobProfileState[x]?.title || 'Select'}
                            width={'w-full'}
                            otherClasses={'border-borderLight mt-2 text-textDark'}
                          />
                        )
                        // <input
                        //   type="text"
                        //   required
                        //   // placeholder="Eg-198"
                        //   autoComplete="off"
                        //   value={jobProfileState[x].title}
                        //   name="title"
                        //   onChange={(e) => handleJobProfileChange(e, x)}
                        //   className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                        // />
                      }
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Supervisor</label>
                      <input
                        type="text"
                        // placeholder="Full Time / Part Time / Contract"
                        value={jobProfileState[x].supervisor}
                        name="supervisor"
                        onChange={(e) => handleJobProfileChange(e, x)}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>
                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">
                        Email<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        value={jobProfileState[x].email}
                        name="email"
                        onChange={(e) => handleJobProfileChange(e, x)}
                        // placeholder="Eg-Jalandhar"
                        onBlur={() => {
                          if (!isValidEmail(jobProfileState[x].email)) {
                            alert('You have entered an invalid email address');
                            handleJobProfileChange({ target: { name: 'email', value: '' } }, x);
                          }
                        }}
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">
                        Contact Number<span className="text-red-500 ml-1">*</span>
                      </label>
                      <input
                        required
                        value={jobProfileState[x].contact}
                        name="contact"
                        onChange={(e) => handleJobProfileChange(e, x)}
                        type="text"
                        onBlur={() => {
                          if (!isValidPhone(jobProfileState[x].contact)) {
                            alert('You have entered an invalid contact number');
                            handleJobProfileChange({ target: { name: 'contact', value: '' } }, x);
                          }
                        }}
                        // placeholder="Eg-Jalandhar"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div>

                    <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      {/* {selectedStaff ? (
                      <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                        {moment(formData.dateOfBirth).format('D MMMM YYYY')}
                      </p>
                    ) : ( */}
                      <DatePicker
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                        selected={jobProfileState[x].startDate && new Date(jobProfileState[x].startDate)}
                        onChange={(date) => handleJobProfileChange({ target: { name: 'startDate', value: date } }, x)}
                        dateFormat={'dd-MM-yyyy'}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {/* )} */}
                    </div>

                    {/* <div className="my-2 w-full relative font-semibold">
                      <label className="text-textDark font-medium text-sm">Salary</label>
                      <input
                        type="text"
                        value={jobProfileState[x].salary}
                        name="salary"
                        onChange={(e) => handleJobProfileChange(e, x)}
                        // placeholder="Eg-Jalandhar"
                        className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                      />
                    </div> */}
                  </div>
                </div>
              ))}
              <div className="flex gap-x-4 justify-between px-8">
                {isEdit && selectedStaff ? null : (
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep((prev) => prev - 1);
                    }}
                  >
                    Back
                  </button>
                )}
                <div className="flex gap-x-4 ml-auto">
                  {selectedStaff && isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                  ) : (
                    <>
                      <button
                        className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={(e) => {
                          e.preventDefault();
                          setJobProfileList([...jobProfileList, jobProfileList.length]);
                          setJobProfileState([...jobProfileState, jobProfileModel]);
                        }}
                      >
                        Add Section
                      </button>
                      <button
                        className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                        type="submit"
                      >
                        {selectedStaff ? 'Save' : 'Next'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <div>
              {jobProfileList.map((x) => (
                <div>
                  <p className=" font-bold text-black pt-4 px-8">Job Profile</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Title</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x]?.title || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Supervisor</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x]?.supervisor || '--'}
                      </p>
                    </div>
                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Email</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x]?.email || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Contact Number</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x]?.contact || '--'}
                      </p>
                    </div>

                    <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Start Date</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x].startDate
                          ? moment(jobProfileState[x].startDate).format('DD-MM-YYYY')
                          : '--'}
                      </p>
                    </div>

                    {/* <div className="my-2 w-full relative">
                      <label className="text-textDark font-medium text-sm">Salary</label>
                      <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                        {jobProfileState[x]?.salary || '--'}
                      </p>
                    </div> */}
                  </div>
                </div>
              ))}
              <div className="flex gap-x-4 justify-between px-8">
                {isEdit && selectedStaff ? null : (
                  <button
                    className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep((prev) => prev - 1);
                    }}
                  >
                    Back
                  </button>
                )}
                <div className="flex gap-x-4">
                  <button
                    className="w-36 border border-primary px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                    onClick={() => {
                      setStep(5);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )
        ) : null}

        {step === 5 ? (
          isEdit ? (
            <form autoComplete="off">
              <p className=" font-bold text-black pt-4 px-8">Salary Account Details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Bank</label>
                  <input
                    type="text"
                    value={bankState?.bank}
                    name="bank"
                    onChange={handlecBankInputChange}
                    // placeholder="Eg. Kotak Mahindra Bank"
                    autoComplete="off"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Account Number</label>
                  <input
                    type="text"
                    value={bankState?.accountNo}
                    name="accountNo"
                    onChange={handlecBankInputChange}
                    // placeholder="Eg. 258669585456878"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">IFSC</label>
                  <input
                    type="text"
                    value={bankState?.ifsc}
                    name="ifsc"
                    onChange={handlecBankInputChange}
                    // placeholder="Eg. KKBK0000111"
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    {isEdit && selectedStaff ? null : (
                      <button
                        className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                        onClick={() => {
                          setStep((prev) => prev - 1);
                        }}
                      >
                        Back
                      </button>
                    )}
                    <button
                      className="w-36 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center ml-auto"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </form>
          ) : (
            <div>
              <p className=" font-bold text-black pt-4 px-8">Salary Account Details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 pb-4 w-full px-8">
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Bank</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                    {bankState?.bank || '--'}
                  </p>
                </div>

                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">Account Number</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                    {bankState?.accountNo || '--'}
                  </p>
                </div>
                <div className="my-2 w-full relative font-semibold">
                  <label className="text-textDark font-medium text-sm">IFSC</label>
                  <p className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none bg-">
                    {bankState?.ifsc || '--'}
                  </p>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between px-8">
                <button
                  className="w-36 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                  onClick={() => {
                    setStep((prev) => prev - 1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default StaffProfile;
