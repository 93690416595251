// const BASE_URL = 'http://localhost';

// export const AUTH = `${BASE_URL}:8000/auth/api`;

// export const ROLE = `${BASE_URL}:8004/roles/api`;

// export const USER = `${BASE_URL}:8002/users/api`;

// export const INSTITUTE = `${BASE_URL}:8003/institute/api`;

// export const FINANCE = `${BASE_URL}:8013/finance/api`;

// export const REMARKS_FEEDBACK = `${BASE_URL}:8016/remarks/api`;

// export const ATTENDANCE = `${BASE_URL}:8008/attendance/api`;

// export const APPOINTMENT = `${BASE_URL}:8018/appointment/api`;

// export const SOCIAL = `${BASE_URL}:8020/social-network/api`;

// export const TIME_TABLE = `${BASE_URL}:8007/timetable/api`;

// export const INSTITUTE_MANAGEMENT = `${BASE_URL}:8006/institute-management/api`;

// export const INCIDENTS = `${BASE_URL}:8019/incidents/api`;

// export const PROFILE = `${BASE_URL}:8001/profile/api`;

// export const NOTES = `${BASE_URL}:8009/notes/api`;

// export const DASHBOARD = `${BASE_URL}:8017/dashboard/api`;

// export const TEST = `${BASE_URL}:8015/test-results/api`;


// export const AI = `${BASE_URL}:7000/api`;

const BASE_URL = 'https://www.roledin.com';


export const AUTH = `${BASE_URL}/auth/api`;

export const ROLE = `${BASE_URL}/roles/api`;

export const USER = `${BASE_URL}/users/api`;

export const INSTITUTE = `${BASE_URL}/institute/api`;

export const FINANCE = `${BASE_URL}/finance/api`;

export const REMARKS_FEEDBACK = `${BASE_URL}/remarks/api`;

export const ATTENDANCE = `${BASE_URL}/attendance/api`;

export const APPOINTMENT = `${BASE_URL}/appointment/api`;

export const SOCIAL = `${BASE_URL}/social-network/api`;

export const TIME_TABLE = `${BASE_URL}/timetable/api`;

export const INSTITUTE_MANAGEMENT = `${BASE_URL}/institute-management/api`;

export const INCIDENTS = `${BASE_URL}/incidents/api`;

export const PROFILE = `${BASE_URL}/profile/api`;

export const NOTES = `${BASE_URL}/notes/api`;

export const DASHBOARD = `${BASE_URL}/dashboard/api`;

export const TEST = `${BASE_URL}/test-results/api`;

export const AI = `${BASE_URL}/api`;
