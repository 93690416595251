import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
// import { OWNER } from '../../../api/urls/dashboard';
import { RECENT_TRANSACTIONS, EXPENSE_REQUEST_LIST, ASK_FOR_JUSTIFICATION } from '../../../api/urls/finance';
import moment from 'moment';
import { STAFF_TODAY_ATTENDANCE } from '../../../api/urls/attendance';
import { useStateValue } from '../../../ContextApi/StateProvider';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const history = useHistory();

  const [{ user }, _] = useStateValue();

  const notificationList = ['Academics', 'Finance', 'Security', 'Principal', 'Staff', 'Task Board'];
  const [showNotification, setShowNotification] = useState(false);
  const [selectedTransaction, selectTransaction] = useState(false);
  const [staffLeaves, setStaffLeaves] = useState([]);
  const [expensesRequests, setExpensesRequests] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);

  const [expenseRequestsRes, getExpenseRequests] = useApi();
  // const [dashboardRes, getDashboardData] = useApi();
  const [recentTransactionsRes, getRecentTransactions] = useApi();
  const [staffLeaveRes, getStaffLeave] = useApi();
  const [askForJustificationRes, askForJustification] = useApi();

  const setNotification = () => {
    setShowNotification((prev) => !prev);
  };

  useEffect(() => {
    // getDashboardData(OWNER, 'GET');
    getRecentTransactions(RECENT_TRANSACTIONS, 'GET');
    getStaffLeave(STAFF_TODAY_ATTENDANCE, 'GET');
    getExpenseRequests(EXPENSE_REQUEST_LIST, 'GET');
  }, []);

  useEffect(() => {
    if (
      !expenseRequestsRes.isLoading &&
      !expenseRequestsRes.isError &&
      expenseRequestsRes.data &&
      Array.isArray(expenseRequestsRes.data)
    ) {
      setExpensesRequests(expenseRequestsRes.data.filter((x) => x.status.toLowerCase() === 'pending'));
    }
  }, [expenseRequestsRes.isLoading, expenseRequestsRes.isError, expenseRequestsRes.data]);

  useEffect(() => {
    if (!recentTransactionsRes.isLoading && !recentTransactionsRes.isError && recentTransactionsRes.data) {
      setRecentTransactions(recentTransactionsRes.data);
    }
  }, [recentTransactionsRes]);

  useEffect(() => {  // console.log(" -- staffLeaveRes : ", staffLeaveRes )
    if (!staffLeaveRes.isLoading && !staffLeaveRes.isError && staffLeaveRes.data) {
      // setStaffLeaves(staffLeaveRes.data);
      // let z = [];
      // let n = [];
      // if (staffLeaveRes.data?.teachingStaff) {
      //   z = staffLeaveRes.data.teachingStaff.filter((x) => x.onLeave.length > 0);
      //   n = staffLeaveRes.data?.nonTeachingStaff.filter((x) => x.onLeave.length > 0);
      // }
      if (staffLeaveRes?.data) setStaffLeaves(staffLeaveRes?.data.filter((x) => x.type === 'leave'));
    }
  }, [staffLeaveRes]);

  const getDetail = (x) => {
    if (x?.category) {
      return x?.name;
    } else if (x?.studentId) {
      return 'Fee';
    }
  };

  const getTimeoffset = (ts) => {
    const _date = new Date(ts);
    return moment(_date).fromNow();
  };

  const handleAskFOrJustification = (id) => {
    askForJustification(ASK_FOR_JUSTIFICATION(id), 'PUT');
  };

  useEffect(() => {
    if (!askForJustificationRes.isLoading && !askForJustificationRes.isError && askForJustificationRes.status == 202) {
      alert('SUCCESS');
      selectTransaction(null);
    }
    if (!askForJustificationRes.isLoading && askForJustificationRes.isError) {
      alert('Error');
    }
  }, [askForJustificationRes]);
  //  console.log({ staffLeaves });
  return (
    <div className="w-full md:w-4/5 ">
      <div className="bg-white rounded-xl h-auto relative pb-6 mb-6">
        {selectedTransaction && (
          <div
            className="flex justify-center items-center h-full w-full fixed left-0 top-0"
            style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))', zIndex: '100' }}
          >
            <div className="bg-white w-5/12 text-sm block m-auto rounded-2xl relative ">
              <div className="w-full justify-between items-center flex px-4 py-4 border-b border-tableHeader">
                <span className="font-bold">
                  {moment(selectedTransaction.date).format('MMM')} - {getDetail(selectedTransaction)}
                </span>
                <img
                  src="assets/img/Close.svg"
                  className=" h-3 cursor-pointer"
                  alt=""
                  onClick={() => selectTransaction(false)}
                />
              </div>
              <div
                className="text-lg p-4 px-6 flex justify-between items-center"
                style={{ borderBottom: '0.71px solid #E7E700' }}
              >
                <span>{`Amount: Rs. ${
                  selectedTransaction.total_fee
                    ? selectedTransaction.total_fee
                    : Array.isArray(selectedTransaction.amount)
                    ? selectedTransaction.amount[0]
                    : selectedTransaction.amount
                }`}</span>
                <span>{`Time: ${moment(selectedTransaction.date).format('ll')}`}</span>
              </div>
              <div className="p-4 flex items-center" style={{ borderBottom: '0.71px solid #E7E700' }}>
                {selectedTransaction.files &&
                  selectedTransaction.files.length > 0 &&
                  selectedTransaction.files[0].location && (
                    <img src={selectedTransaction.files[0].location} alt="" className="w-auto mx-auto h-96" />
                  )}
              </div>

              {/* <div className="w-1/3" /> */}
              <div className="flex items-end  justify-end my-4 px-6">
                <button
                  type="submit"
                  className="w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                  onClick={() => handleAskFOrJustification(selectedTransaction._id)}
                >
                  Ask for Justification
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center px-5 pt-4 pb-2 relative" style={{ borderBottom: '0.71px solid #E7E700' }}>
          <img
            className="schoolLogo mr-4"
            src={user?.institute?.logo?.link || '/assets/img/institute_placeholder.svg'}
          />
          <div className="font-medium">
            <p>{user?.institute?.name}</p>
            <p className="text-xxs text-black30">{user?.institute?.address}</p>
          </div>

          {/* <div
            className="w-10 h-10 rounded-3xl flex items-center justify-center bg-red-200 absolute right-10"
            onClick={setNotification}
          >
            <img className="w-5 h-5" src="assets/img/Notification.svg" />
            <div className="rounded-3xl absolute right-1 bg-red-800 text-white text-center w-4 h-4 text-xs items-center justify-center">
              <div>1</div>
            </div>
          </div> */}
          {/* {showNotification && (
            <div className="h-52 w-44 px-6 pt-5 text-sm font-semibold z-50 absolute shadow-lg bg-white rounded-3xl right-12 top-12">
              {notificationList.map((x) => (
                <div className="py-1 relative flex items-center">
                  {x}
                  {x === 'Finance' && (
                    <div
                      className="rounded-3xl absolute right-1 top-2 bg-red-800 text-white text-center w-4 h-4 text-xs items-center justify-center"
                      onClick={() => selectTransaction(true)}
                    >
                      <div>1</div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )} */}
        </div>
        <div className="flex flex-wrap mx-5 mt-3">
          {/* Budget */}
          <div
            className="w-full md:w-2.25/5 h-80  rounded-xl relative mt-2 overflow-hidden"
            style={{ border: '0.50px solid #E7E700' }}
          >
            <div className="h-5/6 customScrollBar overflow-x-hidden overflow-y-auto px-3">
              {recentTransactions && Array.isArray(recentTransactions) && recentTransactions.length > 0 ? (
                recentTransactions.map((x) => (
                  <div
                    className="mt-5 w-full flex gap-x-3 text-left px-2 items-start"
                    onClick={() => {
                      getDetail(x) !== 'Fee' && selectTransaction(x);
                    }}
                  >
                    <div className="w-6/12 text-sm font-medium">
                      <p>{getDetail(x) === 'Fee' ? x.month + ' Month Fee' : getDetail(x)}</p>
                      <p className="font-normal text-xs text-black30">
                        {x?.studentId ? x?.mode : x?.category?.category}
                      </p>
                    </div>
                    <div className="w-3/12 text-xs font-medium mt-1 px-1 text-textGreen">
                      <p>{getTimeoffset(x.date)}</p>
                    </div>
                    <div
                      className={`w-3/12 text-lg ${
                        getDetail(x) === 'Fee'
                          ? 'text-textGreen'
                          : x?.transcationType?.toLowerCase() === 'credit'
                          ? 'text-textGreen'
                          : 'text-textRed'
                      }`}
                    >
                      <p>
                        {getDetail(x) === 'Fee' ? '+' : x?.transcationType?.toLowerCase() === 'credit' ? '+' : '-'}
                        &#8377;{x.total_fee ? x.total_fee : Array.isArray(x.amount) ? x.amount[0] : x.amount}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-2xl font-light leading-6 mt-28 text-center">No Data Available</p>
              )}
            </div>

            <div
              className=" h-14 w-full flex items-center justify-between"
              style={{ borderTop: '0.5px solid #E7E700' }}
            >
              <span className="ml-3 text-tableRowText">Budget & Transaction</span>
              <button
                onClick={() => history.push('/owner?menu=Budget&Transactions')}
                className="w-28 h-9 mr-3 text-center text-xs px-4 rounded-3xl text-tableRowText"
                style={{ border: '1px solid #212121' }}
              >
                {' '}
                Show More
              </button>
            </div>
          </div>

          <div className="w-1/10" />

          {/* Staff */}
          <div
            className="w-full md:w-2.25/5 h-80 overflow-hidden rounded-xl relative mt-2"
            style={{ border: '0.5px solid #E7E700' }}
          >
            <div className="h-5/6 customScrollBar overflow-x-hidden overflow-y-auto px-3">
              {staffLeaves.length > 0 ? (
                staffLeaves.map((x) => (
                  <div className="mt-5 w-full flex items-center px-3">
                    <div className="w-1/12">
                      {x?.userDetail?.fileArray.length > 0 &&
                      x?.userDetail?.fileArray[0].profileImg &&
                      x?.userDetail?.fileArray[0].profileImg.location ? (
                        <img
                          className="schoolLogo"
                          style={{
                            width: '2em',
                            height: '2em',
                          }}
                          src={x?.userDetail?.fileArray[0].profileImg.location}
                          alt=""
                        />
                      ) : (
                        <img
                          className="schoolLogo"
                          style={{
                            width: '2em',
                            height: '2em',
                          }}
                          src={'/assets/img/user_placeholder.svg'}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="w-2/3 text-sm font-medium pl-3">
                      <p>
                        {x?.userDetail?.full_name} <span>{' (' + x?.userDetail?.username + ')'}</span>
                      </p>
                      <p className="font-normal text-gray-400 text-xs">{`${moment(x?.date).format('DD-MM-YYYY')} `}</p>
                    </div>
                    <div className="w-1/6 ml-auto text-tableRowText text-xs flex text-right items-center">
                      {/* {x?.days > 0 ? `(${x?.days} Days)` : '(Today)'} */}
                      {'(Today)'}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-tableRowText text-2xl font-light leading-6 mt-28 text-center">No Data Available</p>
              )}
            </div>
            <div
              className=" h-14 w-full flex items-center justify-between"
              style={{ borderTop: '0.5px solid #E7E700' }}
            >
              <span className="ml-3 text-tableRowText">Staff</span>
              <button
                onClick={() => history.push('/owner?menu=Staff')}
                className="w-28 h-9 mr-3 text-center text-xs px-4 rounded-3xl text-tableRowText"
                style={{ border: '1px solid #212121' }}
              >
                {' '}
                Show More
              </button>
            </div>
          </div>

          {/* Pending Requests */}
          <div
            className="w-full md:w-2.25/5 h-80 rounded-xl relative mt-5 customScrollBar"
            style={{ border: '0.50px solid #E7E700' }}
          >
            {expensesRequests.length > 0 ? (
              expensesRequests.map((x) => (
                <div className="mt-5 w-full flex px-3 items-center gap-x-4">
                  <p
                    className="w-1/4 text-black text-xxs text-center py-1.5 rounded-md"
                    style={{ border: '0.36px solid #00000080' }}
                  >
                    {x?.category?.category}
                  </p>
                  <div className="w-2/4 px-2">
                    <p className="text-textDark font-normal text-sm">Raised by FINANCE</p>{' '}
                    <p className="font-normal text-xs text-textLight">{x?.name}</p>
                  </div>
                  <div className="w-1/4 text-lg text-textDark">{'\u20B9' + ' ' + x?.amount}</div>
                </div>
              ))
            ) : (
              <p className="text-tableRowText text-2xl font-light leading-6 mt-28 text-center">No Data Available</p>
            )}

            <div
              className="absolute bottom-0 h-14 w-full flex items-center justify-between"
              style={{ borderTop: '0.5px solid #E7E700' }}
            >
              <span className="ml-3 text-tableRowText">Pending Requests</span>
              <button
                onClick={() => history.push('/owner?menu=Budget & Transactions&pending=true')}
                className="w-28 h-9 mr-3 text-center text-xs px-4 rounded-3xl text-tableRowText"
                style={{ border: '1px solid #212121' }}
              >
                {' '}
                Show More
              </button>
            </div>
          </div>

          <div className="w-1/10" />

          {/* <div className="w-full md:w-2.25/5 h-80 rounded-xl relative mt-5 border">
            <div
              className="border m-2 rounded-xl flex text-center text-gray-400 text-3xl items-center justify-center"
              style={{ height: '19rem' }}
            >
              Add Tab
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
