import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import {
  STUDENT_FEE,
  GET_FEE_STRUCTURE_FOR_STUDENT,
  GET_BALANCE_FEE,
  MISC_FEE,
  INVENTORY,
} from '../../../api/urls/finance';
import { GET_STUDENT_SIBILING } from '../../../api/urls/role';
import DropDown from '../../../components/UI/DropDown';
import { MONTHS } from '../../../constants';
import { SECTION_STUDENTS } from '../../../api/urls/institute';
import FeeReceipt from '../ClassWiseFeeStructure/FeeReceipt';
import PendingFeeDetails from './PendingFeeDetails';
import SiblingDetails from './SiblingDetails' ; 


function DepositFee({ combinedClassSection, refreshList, print }) {
  const formDataModel = {
    fee_id: '',
    fee_cycle: '',
    fatherName: '',
    payment: 'Cash', //mode
    total_fee: '',
    breakdown: [],
    feeUpload: null,
    userName: '',
    name: '',
    classSec: '',
    status: 'approved',
    misc: [],
  };

  const miscFeeModel = {
    payment: '',
    month: '',
    amount: '',
    status: 'pending',
    orderType: [],
    amountPaid: '',
  };

  const [formData, setFormData] = useState(formDataModel);
  const [miscFeeState, setMiscFeeState] = useState(miscFeeModel);

  const [reset, setReset] = useState(0); //to reset dropdowns
  const [selectedStudent, selectStudent] = useState();
  const [selectedClass, selectClass] = useState();
  const [studentList, setStudents] = useState([]);
  const [openFeeReceipt, setOpenFeeReceipt] = useState(false);
  const [_balance, setBalance] = useState(0);
  const [feeWaiveOff, setWaiveOff] = useState(0);
  const [feeType, setFeeType] = useState('');
  const [details, setOpenDetails] = useState();
  const [InventoryList, setinventory] = useState();
  const [studentSelected, setStudentSelected] = useState(null);
  const [restSibling, setrestSibling ] = useState([]);

  const [uploadFeeRes, UploadFee] = useApi();
  const [balanceFeeRes, getBalanceFee] = useApi();
  const [students, getStudents] = useApi();
  const [feeStructureRes, getFeeStructure] = useApi();
  const [inventoryRes, getInventory] = useApi();
  const [sibling, getsibling] = useApi();
  // const [pendingMiscFeeRes, getPendingMiscFee] = useApi();
  // const [updateMiscFeeRes, updatePendingMiscFee] = useApi();

  useEffect(() => {
   
    if (!sibling.isLoading && !sibling.isError && sibling.data) { console.log(" -- ", sibling.data.studentlist )
      setrestSibling(sibling.data.studentlist);
    }
  }, [ sibling]);

  useEffect(() => {
    if (!students.isLoading && !students.isError && students.data) {
      setStudents(students.data);
    }
   
  }, [students]);

  useEffect(() => { 
    getInventory(INVENTORY, 'GET');
  }, []);

  useEffect(() => {
    if (!inventoryRes.isLoading && !inventoryRes.isError && inventoryRes.data && Array.isArray(inventoryRes.data)) {
      setinventory(
        inventoryRes.data
          .filter((x) => x.quantity > 0)
          .map((x) => {
            return {
              name: `${x.type} ${x.size ? `(${x.size})` : ''}`,
              amount: x.price,
              id: x._id,
            };
          })
      );
    }
  }, [inventoryRes]);

  // const handleUpdateMiscFee = (id, amount) => {
  //   updatePendingMiscFee(MISC_FEE(id), 'PUT', { amountPaid: amount });
  // };

  // useEffect(() => {
  //   if (pendingMiscFeeRes.data?.length) {
  //     setMiscFeeState({ ...miscFeeState, orderType: pendingMiscFeeRes.data[0]?.orderType });
  //   }
  // }, [pendingMiscFeeRes]);

  const onclassSelect = (x) => { // console.log("class select object", x )
    setStudents([]);
    selectClass(x.name);
    setFormData({ ...formData, classSec: x.name, name: '', userName: '' });
    selectStudent(null);
    setStudentSelected(null);
    setrestSibling([]);
    getStudents(SECTION_STUDENTS(x.classId, x.sectionId), 'GET');
  }

  const onStudentSelect = (x) => { // console.log(" x.classSec : ", x.classSec );
    let parentsNameStr = ""
    if(x.parent1 ){parentsNameStr = parentsNameStr + x.parent1.full_name + "," }
    if(x.parent2 ){parentsNameStr = parentsNameStr + x.parent2.full_name + "," }
    parentsNameStr = parentsNameStr.slice(0, -1); 
    let className =  formData.classSec ;
    let classSecArry = formData.classSec.split("-") ; 
    if(x.classSec &&  x.classSec != "" ){ classSecArry = x.classSec.split("-") ; className = x.classSec  }
    if(classSecArry.length < 2 ){ classSecArry.push("") ; }
    selectStudent(x);
    // console.log(" className : ", className );
    setFormData({
      ...formData,
      classSec:className,
      userName: x.username, // roledin Id 
      name: x?.full_name, // name
      id: x._id,
    });
    setStudentSelected({full_name:x?.full_name,roledinId:x.username,parents : parentsNameStr, class : {name:classSecArry[0].trim()} , section : {name:classSecArry[1].trim() } })
    getFeeStructure(GET_FEE_STRUCTURE_FOR_STUDENT(x._id), 'GET');
    getBalanceFee(GET_BALANCE_FEE(x._id), 'GET'); 
    getsibling(GET_STUDENT_SIBILING(x._id), 'GET'); 
    setrestSibling([]);  
  }
  
  //
  const SetClassAndStudent = (sb) => {  // console.log(" -- sibling selected :", studentList )
    selectClass(sb?.class?.name +" - "+ sb?.section?.name); 
    onStudentSelect({parent1 : sb?.parent1 ,parent2 : sb?.parent2 , classSec : sb?.class?.name +" - "+ sb?.section?.name , username:sb?.username,full_name:sb?.full_name,_id:sb?._id  })
    getStudents(SECTION_STUDENTS(sb?.class?._id, sb?.section?._id ), 'GET');
  }
  const handleSubmit = (balanceFee, actualFee, monthlyFee) => {
    const prevBalance = balanceFeeRes?.data?.carryFrwdAmount?.prevBal || 0;

    if (feeType === 'Regular') {
      const fd = new FormData();
      let str = [];
      if (!formData.total_fee) str.push('Amount');
      if (!formData.userName) str.push('Student');
      if (!formData.month) str.push('Month');

      if (str.length > 0) {
        alert(`Please fill required fields: ${str.join(', ')}`);
        return;
      }

      if (formData.total_fee <= 0) {
        alert('Amount must be greater than zero');
        return;
      }

      const currentBalance = balanceFee - feeWaiveOff;

      if (currentBalance < prevBalance) fd.append('previousPayment', prevBalance - currentBalance);
      else fd.append('previousPayment', 0);

      fd.append('payment', formData.payment);
      fd.append('amount', formData.total_fee);
      fd.append('month', formData.month);
      fd.append('date', new Date());
      fd.append('userName', formData.userName);
      fd.append('status', formData.status);
      fd.append('carryFrwd', currentBalance);
      fd.append('waiveoff', feeWaiveOff);
      fd.append('actualMonthFee', actualFee);
      fd.append('prevBalance', prevBalance);
      fd.append('monthlyFee', monthlyFee);
      fd.append('optionalFeeSelected', !!formData?.misc);
      fd.append('miscFee', formData?.misc ? JSON.stringify(formData?.misc) : []);

      setBalance(currentBalance);

      UploadFee(STUDENT_FEE(), 'POST', fd);
    } else {
      let str = [];
      if (!miscFeeState.amountPaid) str.push('Payment');
      if (!formData.userName) str.push('Student');

      if (str.length > 0) {
        alert(`Please fill required fields: ${str.join(', ')}`);
        return;
      }

      if (miscFeeState.amountPaid <= 0) {
        alert('Amount must be greater than zero');
        return;
      }
      const fd = new FormData();
      const amount = miscFeeState.orderType?.length
        ? miscFeeState.orderType?.length === 1 && miscFeeState.orderType[0].name === 'Adjust balance'
          ? 0
          : miscFeeState.orderType?.reduce((acc, cu) => (acc += +cu.amount), 0)
        : 0;
      // fd.append('amount', amount);
      fd.append('month', MONTHS[new Date().getMonth()]);
      // fd.append('status', miscFeeState.status);
      fd.append('amountPaid', miscFeeState.amountPaid);
      fd.append('orderType', miscFeeState.orderType ? JSON.stringify(miscFeeState.orderType) : []);
      fd.append('totalAmount', amount + prevBalance);
      fd.append('prevBalance', prevBalance);
      fd.append('waiveoff', feeWaiveOff);
      fd.append('mode', 'Cash');

      UploadFee(MISC_FEE(formData.id), 'POST', fd);
    }
  };

  useEffect(() => {
    if (!uploadFeeRes.isLoading && !uploadFeeRes.isError && [201, 202, 200, 204].includes(uploadFeeRes.status)) {
      if (feeType === 'Regular') {
        print({
          student: selectedStudent,
          fee: uploadFeeRes?.data?.paymentData,
          class: { name: selectedClass.split('-')[0] },
          section: { name: selectedClass.split('-')[1] },
          prevBal: balanceFeeRes?.data?.carryFrwdAmount?.prevBal || 0,
        });
      } else {
        print({
          student: selectedStudent,
          fee: {
            month: MONTHS[new Date().getMonth()],
            amount: uploadFeeRes?.data?.amountPaid,
            miscFee: miscFeeState?.orderType,
            balance: uploadFeeRes?.data?.bal,
            receiptNo: uploadFeeRes?.data?.receipt,
            date: uploadFeeRes?.data?.date,
            totalAmount: uploadFeeRes?.data?.amount,
            prevBalance: uploadFeeRes?.data?.prevBalance,
            waiveoff: uploadFeeRes?.data?.waiveoff,
          },
          class: { name: selectedClass.split('-')[0] },
          section: { name: selectedClass.split('-')[1] },
        });
        getInventory(INVENTORY, 'GET');
        // getPendingMiscFee(MISC_FEE(formData?.id), 'GET');
      }
      alert('SUCCESS');
      setWaiveOff(0);
      setBalance(0);
      setReset((prev) => (prev += 1));
      refreshList();
      setFeeType('');
      setFormData({ ...formDataModel });
      setMiscFeeState({ ...miscFeeModel });
      setStudents([]);
    }

    if (uploadFeeRes.isError) {
      alert(uploadFeeRes.data || 'Failed to upload');
    }
  }, [uploadFeeRes]);

  // useEffect(() => {
  //   if (
  //     !updateMiscFeeRes.isLoading &&
  //     !updateMiscFeeRes.isError &&
  //     [201, 202, 200, 204].includes(updateMiscFeeRes.status)
  //   ) {
  //     // getPendingMiscFee(MISC_FEE(formData?.id), 'GET');
  //     alert('SUCCESS');
  //     setReset((prev) => (prev += 1));
  //     setMiscFeeState({ ...miscFeeModel });
  //     setFeeType('');
  //   }

  //   if (updateMiscFeeRes.isError) {
  //     alert(updateMiscFeeRes.data || 'Failed');
  //   }
  // }, [updateMiscFeeRes]);

  const handleCloseFeeReceipt = () => {
    setOpenFeeReceipt(false);
  };

  let monthWiseFee = {};

  let fees = feeStructureRes?.data;

  if (fees) {
    const { annualFee, examFee, student } = fees;

    const discount = student?.discount;

    for (const { month } of annualFee) {
      if (discount) {
        monthWiseFee[month] = [
          ...fees.monthlyFee?.map((x) => {
            const actualAmount = x?.amount;
            const amount = discount?.inPercent
              ? x.amount - (x.amount * +discount?.discount) / 100
              : x.amount - +discount?.discount;
            return {
              ...x,
              discount: discount?.inPercent ? discount?.discount + '%' : 'Rs. ' + discount?.discount,
              amount,
              actualAmount,
            };
          }),
        ];
      } else {
        monthWiseFee[month] = [...fees.monthlyFee];
      }
      if (fees?.transportFee) {
        monthWiseFee[month] = [...monthWiseFee[month], { name: 'Transport Fee', amount: fees?.transportFee }];
      }

      let _annualFee = annualFee.filter((item) => item.month === month && item?.fee);
      if (_annualFee.length > 0) {
        _annualFee.forEach((x) => {
          monthWiseFee[month] = [...monthWiseFee[month], ...x.fee];
        });
      }

      let _examFee = examFee.filter((item) => item.month === month);
      if (_examFee.length > 0) {
        _examFee.forEach((x) => {
          monthWiseFee[month] = [
            ...monthWiseFee[month],
            {
              name: 'Exam Fee',
              amount: x.fee,
            },
          ];
        });
      }
    }
  }

  let monthFee = '';
  if (fees?.monthlyFee?.length && formData.month) {
    monthFee = monthWiseFee[formData.month].reduce((a, c) => {
      a += +c.amount;
      return a;
    }, 0);
  }

  const previousBalance = (formData?.name && balanceFeeRes?.data?.carryFrwdAmount?.prevBal) || 0;
  const miscFee = formData?.misc?.length ? formData?.misc?.reduce((acc, cu) => (acc += +cu.amount), 0) : 0;
  const feeToBePaid = +monthFee + +previousBalance + +miscFee;

  const miscFee2 = miscFeeState?.orderType?.length
    ? miscFeeState.orderType?.filter((x) => x.name !== 'Adjust balance')?.reduce((acc, cu) => (acc += +cu.amount), 0)
    : 0;

  let balance = 0;
  if (monthFee > 0 && +formData?.total_fee > 0) balance = feeToBePaid - +formData.total_fee;
  balance -= feeWaiveOff;

  let balance2 = miscFee2 + +previousBalance - feeWaiveOff - +miscFeeState?.amountPaid;
  
  return (
    <div className="w-full bg-white rounded-xl h-auto">
      {openFeeReceipt && (
        <FeeReceipt
          handleClose={handleCloseFeeReceipt}
          fees={{ receipt: feeStructureRes?.data, role: 'finance_payment' }}
        />
      )}

      {details && <PendingFeeDetails data={details?.carryFrwdAmount} onClose={() => setOpenDetails(null)} />}

      <div className="items-center">
        <div
          className="flex w-full justify-between items-center relative px-6"
          style={{ borderBottom: '0.71px solid #E7E700' }}
        >
          <p className="block font-bold py-4 text-textBlue1">Deposite Fee</p>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-4 pb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 px-4 mt-2 gap-x-6 w-full">

          <div className="my-2  relative font-semibold w-full pr-2">
            <label className="block text-sm text-textDark">
              Class<span className="text-red-500 ml-1">*</span>
            </label>
            <DropDown
              field={'name'}
              handleItemClick={onclassSelect}
              title={formData.classSec || 'Select class'}
              data={combinedClassSection}
              width={'w-full'}
              key={reset}
              margin={'mt-2'}
              otherClasses={'border-borderLight'}
              type={'obj'}
            />
          </div>

          <div className="my-2 relative font-semibold w-full">
            <label className="block text-sm text-textDark">
              Name<span className="text-red-500 ml-1">*</span>
            </label>
            <DropDown
              field={'full_name'}
              handleItemClick={onStudentSelect}
              title={formData.name || 'Select Student'}
              data={studentList}
              width={'w-full'}
              margin={'mt-2'}
              otherClasses={'border-borderLight'}
              type={'obj'}
              key={reset}
            />
            {/* <input
              type="text"
              autoComplete="off"
              value={formData.userName}
              className="w-full text-xs rounded-3xl border border-borderLight px-4 mt-2 py-2 focus:outline-none"
              onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
            /> */}
          </div>

          <SiblingDetails key={restSibling} onSibling={SetClassAndStudent}  currStudent={studentSelected} SiblingList={restSibling} />

          <div className="my-2  relative font-semibold w-full">
            <label className="block text-sm text-textDark">
              Fee Type<span className="text-red-500 ml-1">*</span>
            </label>
            <DropDown
              title={'Select'}
              key={reset}
              handleItemClick={(x) => {
                setReset((prev) => prev++);
                setFeeType(x);
                setMiscFeeState({ ...miscFeeModel });
              }}
              margin={'mt-2'}
              data={['Regular', 'Miscellaneous']}
              otherClasses={'border-borderLight'}
            />
          </div>

          {/* <div className="my-2  relative font-semibold w-full">
            <label className="block text-sm text-textDark">ROLEDin ID</label>
            <input
              type="text"
              value={formData.userName}
              disabled
              className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
            />
          </div> */}

          {feeType === 'Regular' ? (
            <>
              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">
                  Month<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  title={formData?.month || 'Select'}
                  key={reset}
                  handleItemClick={(x) => {
                    setFormData({ ...formData, month: x });
                  }}
                  margin={'mt-2'}
                  data={MONTHS}
                  otherClasses={'border-borderLight'}
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Current Month Fee</label>
                <input
                  type="text"
                  value={monthFee}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">
                  {previousBalance < 0 ? 'Advance' : 'Previous Balance'}
                </label>
                <div className="relative flex">
                  <input
                    type="number"
                    value={Math.abs(previousBalance)}
                    disabled
                    className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDetails(balanceFeeRes?.data);
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>

              {/* <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Previous Balance</label>
                <input
                  type="text"
                  value={previousBalance}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div> */}

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Miscellaneous Fee (Multiple)</label>
                <DropDown
                  title={formData.misc.length ? '' : 'Select'}
                  key={reset}
                  handleItemClick={(x) => {
                    setFormData({ ...formData, misc: [...formData.misc, x] });
                  }}
                  margin={'mt-2'}
                  type={'obj'}
                  field={'name'}
                  data={fees?.miscFee ? [...fees?.miscFee, ...InventoryList] : [...InventoryList] || []}
                  otherClasses={'border-borderLight'}
                />

                <div className="p-2 flex flex-wrap gap-2 w-full overflow-auto customScrollBar">
                  {Array.isArray(formData.misc) &&
                    formData.misc.map((x) => (
                      <div className="gap-x-2 rounded-3xl flex justify-between text-xs bg-tableHeader border px-3 py-1.5 focus:outline-none">
                        <span>{`${x.name} ${x?.amount ? '(Rs. ' + x.amount + ')' : ''}`}</span>
                        <img
                          src="assets/img/Close.svg"
                          alt=""
                          className="cursor-pointer"
                          onClick={() => {
                            setFormData({ ...formData, misc: formData.misc.filter((y) => x.name !== y.name) });
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Miscellaneous Amount</label>
                <input
                  type="text"
                  value={miscFee}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Fee to be paid (Current + Previous Balance)</label>
                <input
                  type="text"
                  value={feeToBePaid}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">
                  Amount<span className="text-red-500 ml-1">*</span>
                </label>
                <div className="relative flex">
                  <input
                    type="number"
                    value={formData.total_fee}
                    placeholder="Eg-1000"
                    onChange={(e) => {
                      let inputValue = e.target.value || '';
                      if (e.target.value.startsWith('-')) {
                        inputValue = e.target.value.slice(1);
                      }
                      setFormData({ ...formData, total_fee: inputValue });
                    }}
                    className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenFeeReceipt({ receipt: feeStructureRes?.data, role: 'Parent' });
                    }}
                  >
                    View Fee Breakup
                  </button>
                </div>
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Fee Waive Off</label>
                <input
                  type="number"
                  value={feeWaiveOff}
                  onChange={(e) => setWaiveOff(e.target.value)}
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">{balance < 0 ? 'Advance' : 'Balance'}</label>
                <input
                  type="text"
                  value={Math.abs(balance)}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
            </>
          ) : feeType === 'Miscellaneous' ? (
            <>
              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Miscellaneous Fee (Multiple)</label>
                <DropDown
                  title={'Select'}
                  key={reset || miscFeeState?.orderType}
                  handleItemClick={(x) => {
                    if (x?.name === 'Adjust balance') setMiscFeeState({ ...miscFeeState, orderType: [x] });
                    else setMiscFeeState({ ...miscFeeState, orderType: [...miscFeeState.orderType, x] });
                  }}
                  margin={'mt-2'}
                  type={'obj'}
                  field={'name'}
                  data={
                    fees?.miscFee
                      ? [ { name: 'Adjust balance' }, ...fees?.miscFee, ...InventoryList]
                      : [ { name: 'Adjust balance' },...InventoryList]
                  }
                  otherClasses={'border-borderLight'}
                />

                <div className="pt-2 flex flex-wrap gap-2 w-full overflow-auto customScrollBar">
                  {Array.isArray(miscFeeState.orderType) &&
                    miscFeeState.orderType.map((x) => (
                      <div className="gap-x-2 rounded-3xl flex justify-between text-xs bg-tableHeader border px-3 py-1.5 focus:outline-none">
                        <span>{`${x.name} ${x?.amount ? '(Rs. ' + x.amount + ')' : ''}`}</span>
                        <img
                          src="assets/img/Close.svg"
                          alt=""
                          className="cursor-pointer"
                          onClick={() => {
                            setMiscFeeState({
                              ...miscFeeState,
                              orderType: miscFeeState.orderType.filter((y) => x.name !== y.name),
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>
                {miscFeeState?.orderType.find((c) => c.name === 'Adjust balance') && (
                  <div className="text-xs bg-red-400 p-1 rounded-md mt-1">
                    <span className="">
                      Adjust balance is only used when previous balance is being cleared and no new entry is done
                    </span>
                  </div>
                )}
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Current Amount</label>
                <input
                  type="text"
                  value={miscFee2}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">
                  {previousBalance < 0 ? 'Advance' : 'Previous Balance'}
                </label>
                <div className="relative flex">
                  <input
                    type="number"
                    value={Math.abs(previousBalance)}
                    disabled
                    className="w-full  rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-2 absolute right-0 bottom-0 top-0"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDetails(balanceFeeRes?.data);
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>

              {!(miscFeeState?.orderType?.length === 1 && miscFeeState?.orderType[0].name === 'Adjust balance') && (
                <div className="my-2  relative font-semibold w-full">
                  <label className="block text-sm text-textDark">Total Amount (Current + Previous)</label>
                  <input
                    type="text"
                    value={miscFee2 + +previousBalance}
                    disabled
                    className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
              )}

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Fee Waive Off</label>
                <input
                  type="number"
                  value={feeWaiveOff}
                  onChange={(e) => setWaiveOff(e.target.value)}
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              {/* {pendingMiscFeeRes?.data?.length > 0 && pendingMiscFeeRes?.data[0]?.carryFrwdAmount && (
                <div className="my-2  relative font-semibold w-full">
                  <label className="block text-sm text-textDark">Pending Amount</label>
                  <input
                    type="text"
                    value={pendingMiscFeeRes?.data[0]?.carryFrwdAmount || 0}
                    disabled
                    className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                </div>
              )} */}

              <div className="my-2  relative font-semibold w-full">
                <label className="block text-sm text-textDark">Payment</label>
                <input
                  type="number"
                  value={miscFeeState?.amountPaid}
                  onChange={(e) => setMiscFeeState({ ...miscFeeState, amountPaid: e.target.value })}
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>

              <div>
                <label className="block text-sm text-textDark">{balance2 < 0 ? 'Advance' : 'Balance'}</label>
                <input
                  type="text"
                  value={Math.abs(balance2)}
                  disabled
                  className="w-full text-xs rounded-3xl border  border-borderLight px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="w-full justify-end mt-4">
          <button
            className="border px-8 py-2 w-36 float-right rounded-3xl bg-primary text-xs text-white mx-1 focus:outline-none"
            onClick={() => {
              handleSubmit(balance, feeToBePaid, monthFee);
            }}
          >
            Submit
          </button>
          <button
            className="border border-borderLight w-36 px-8 py-2 float-right rounded-3xl text-xs mx-1 focus:outline-none"
            onClick={() => {
              setReset((prev) => (prev += 1));
              setFormData({ ...formDataModel });
              setStudents([]);
            }}
          >
            Cancel
          </button>
        </div>

        {/* {(pendingMiscFeeRes.data?.length > 0 || balanceFeeRes?.data?.carryFrwdAmount?.prevBal > 0) && (
          <div className="mt-4 w-full">
            <p className="font-semibold pl-6">Pending Payments</p>
            <div className="w-full py-4 overflow-auto">
              <div
                className="bg-white m-auto border rounded-2xl min-w-700"
                style={{ borderColor: '#E7E700', width: '96%' }}
              >
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-xs font-medium px-2">
                    <div className="w-2/6 border-r-2 py-2 px-2">Item</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Total amount</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Paid</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Pending</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '40vh' }}>
                  {pendingMiscFeeRes?.data &&
                    Array.isArray(pendingMiscFeeRes?.data) &&
                    pendingMiscFeeRes?.data?.map((x, idx) => (
                      <div className="w-full" key={x?._id}>
                        <div
                          className={`items-center flex w-full relative py-2 px-2 text-xs font-medium border-b border-tableHeader`}
                        >
                          <div className="w-2/6 px-2">
                            {x?.orderType.map((c) => `${c.name} (Rs. ${c.amount})`).join(', ')}
                          </div>
                          <div className="w-1/6 px-2">{x?.amount}</div>
                          <div className="w-1/6 px-2">{x?.amountPaid || '--'}</div>
                          <div className="w-1/6 px-2">{x?.carryFrwdAmount || '--'}</div>
                          <div className="w-1/6 px-2">
                            <button
                              className="border border-primary text-primary w-full py-2 rounded-3xl text-xs mx-1 focus:outline-none"
                              onClick={() => {
                                handleUpdateMiscFee(x._id, +x?.carryFrwdAmount);
                              }}
                            >
                              Pay
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="w-full">
                    <div className={`items-center flex w-full relative py-2 px-2 text-xs font-medium`}>
                      <div className="w-2/6 px-2">Monthly Fee Balance</div>
                      <div className="w-1/6 px-2">{'--'}</div>
                      <div className="w-1/6 px-2">{'--'}</div>
                      <div className="w-1/6 px-2">{previousBalance}</div>
                      <div className="w-1/6 px-2">
                        <button
                          className="border border-primary text-primary w-full py-2 rounded-3xl text-xs mx-1 focus:outline-none"
                          onClick={() => {
                            // handleUpdateMiscFee(x._id, +x?.carryFrwdAmount);
                          }}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default DepositFee;
