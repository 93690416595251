import React, { useState, useEffect } from 'react';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import useApi from '../../../api/useApi';
import { UPDATE_CALANDER_EXAMS } from '../../../api/urls/institute';
import LoadingPopup from './LoadingPopup';
import { getRandomNumber, getExamName } from '../../../utils';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Preview = ({ movePrevious, openAddEvent, options, calanderId, showCalander }) => {
  const [selected, select] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [res, publish] = useApi();

  const handlePublish = () => {
    if (!window.confirm('Are you sure you want to Publish?')) return;

    if (!selected) {
      alert('Please select any one option');
      return;
    }
    const body = {
      examDateList: options[selected],
    };

    setOpenLoader(true);
    publish(UPDATE_CALANDER_EXAMS(calanderId), 'PUT', body);
  };

  useEffect(() => {
    if (res?.status === 200) {
      setTimeout(() => {
        // alert('Success');
        showCalander(res.data);
        setOpenLoader(false);
      }, getRandomNumber(3, 6, false) * 1000);
    }

    if (res.isError) {
      alert('Error saving');
      setOpenLoader(false);
    }
  }, [res]);

  return (
    <div className="p-4">
      {openLoader && <LoadingPopup text={'Publishing Calendar...'} />}
      <div className="flex justify-between items-center mb-4">
        <button className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none">
          Edit
        </button>

        <button
          className="w-28 py-2 text-xs rounded-3xl bg-primary border border-prbg-primary font-medium text-white focus:outline-none"
          onClick={openAddEvent}
        >
          Add Event
        </button>
      </div>

      <div className="bg-white m-auto w-full">
        {/* <Calendar
          localizer={localizer}
          events={[]}
          style={{ height: 450 }}
          views={['month']}
          // eventPropGetter={eventPropGetter}
        /> */}
        <h3 className="mt-6 mb-6 text-center font-semibold text-xl">Select One option and click on publish</h3>
        <div className="flex gap-6 mx-auto w-4/5">
          {Object.entries(options).map((x) => (
            <div
              className={`flex flex-col border-2 border-borderYellow w-full px-6 py-4 rounded-lg shadow-sm hover:shadow-2xl cursor-pointer ${
                selected === x[0] ? 'transform scale-110 bg-primaryLight' : ''
              }`}
              onClick={() => select(x[0])}
            >
              <p className="capitalize text-center font-medium mb-3">{x[0]}</p>

              <div className="flex flex-col">
                <div className="flex gap-x-4 justify-between">
                  <p className="font-semibold text-textLight">Exam</p>
                  <p className="font-semibold text-textLight ">Teaching Days</p>
                </div>

                {x[1].map((c) => (
                  <div className="flex gap-x-4 justify-between">
                    <p className="">{getExamName(c.key)}</p>
                    <p className="w-1/2 text-center">{c.days}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-end items-center mt-6 px-6">
        {/* <button className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none">
          Reset
        </button> */}

        <div className="flex gap-x-4">
          {/* <button
            className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none"
            onClick={movePrevious}
          >
            Back
          </button> */}
          {/* <button
            className="w-28 py-2 text-xs rounded-3xl bg-bgBlue border border-bgBlue font-medium text-white focus:outline-none"
            onClick={() => {}}
          >
            Save
          </button> */}
          <button
            className="w-28 py-2 text-xs rounded-3xl bg-white border border-borderLight font-medium text-tableRowText focus:outline-none"
            onClick={handlePublish}
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
