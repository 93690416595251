import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import useApi from '../../../api/useApi';
import { EVENT } from '../../../api/urls/timeTable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import DropDown from '../../../components/UI/DropDown';

const AddEvent = ({ handleCloseEvent }) => {
  const formDataModel = {
    title: '',
    type: '',
    eventDate: '',
    start: null,
    end: null,
  };
  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createEvent] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    let str = [];
    if (!formData?.title?.trim()) str.push('Title');
    if (!formData.eventDate) str.push('Event Date');
    if (!formData.start) str.push('Start Time');
    if (!formData.end) str.push('End Time');

    if (str.length > 0) {
      alert(`Please fill required fields: ${str.join(', ')}`);
      return;
    }

    if (new Date(formData.start).getTime() >= new Date(formData.end).getTime()) {
      alert('Start time should be before end time');
      return;
    }

    const fd = new FormData();
    fd.append('title', formData.title);
    fd.append('eventDate', formData.eventDate);
    fd.append('type', formData.type);
    fd.append('start', formData.start);
    fd.append('end', formData.end);

    // createEvent(EVENT, 'POST', fd);
  };

  useEffect(() => {
    if (!isLoading && !isError && status === 201) {
      alert('Event created successfully');
      setFormData(formDataModel);
    }

    if (!isLoading && isError) {
      alert('Failed!');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-1/2 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">Add Event (Academic Calender 2024-2025)</span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleCloseEvent} />
          </div>
        </div>

        <div className="w-full px-4 pb-6">
          <form className="grid grid-cols-1 md:grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
            <div className="my-2 relative font-semibold w-full">
              <label className="w-full text-sm text-textDark">
                Select Date<span className="text-red-500 ml-1">*</span>
              </label>
              <DatePicker
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                selected={formData.eventDate}
                onChange={(date) => setFormData({ ...formData, eventDate: date })}
                dateFormat={'dd-MM-yyyy'}
                required
                placeholderText="Select date"
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className=" text-sm text-textDark">Event Type</label>
              <DropDown
                data={['Picnic', 'Function']}
                handleItemClick={(e) => {
                  setFormData({ ...formData, type: e });
                }}
                title={'Select'}
                margin={'mt-2'}
                otherClasses={'border border-borderLight text-textDark'}
              />
            </div>
            <div className="my-2 relative font-semibold w-full">
              <label className=" text-sm text-textDark  mb-2">
                Start Time<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="mt-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label=""
                    value={formData?.start}
                    onChange={(time) => setFormData({ ...formData, start: time })}
                    slotProps={{ textField: { size: 'small' } }}
                    required
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className=" text-sm text-textDark mb-2">
                End Time<span className="text-red-500 ml-1">*</span>
              </label>
              <div className="mt-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label=""
                    value={formData?.end}
                    onChange={(time) => setFormData({ ...formData, end: time })}
                    slotProps={{ textField: { size: 'small' } }}
                    required
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="my-2 w-full relative font-semibold ">
              <label className=" text-sm text-textDark">
                Title<span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="title"
                placeholder="Enter event name"
                required
                value={formData.title}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>

            <div className="w-full mt-4 flex justify-end md:col-span-2 gap-x-4">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <button
                    type="reset"
                    className="border border-tableRowText text-tableRowText px-12 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                    onClick={() => {
                      setFormData({ ...formDataModel });
                    }}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEvent;
