import React from 'react';
import { useLocation } from 'react-router-dom';

import Dashboard from '../Parents/Dashboard';
import Attendance from '../Parents/Attendance';
import CourseDetails from '../Parents/CourseDetails';
import EventsTimetable from '../Parents/EventsTimetable';
import LectureSummary from '../Parents/LectureSummary';
import LiveSessions from '../Parents/LiveSessions';
import Assignment from '../Parents/Assignment';
import MarksResult from '../Parents/MarksResult';
import Fee from '../Parents/Fee';
import Feedback from '../Parents/Feedback';
import NextAdmission from '../Parents/NextAdmission';
import HomeWork from '../Parents/HomeWork';
import AcademicCalender from '../Principal/AcademicCalender';

function index() {
  const location = useLocation();
  return (
    <div>
      {location.search === '' && <Dashboard role={'student'} />}
      {location.search.includes('AcademicCalender') && <AcademicCalender />}
      {location.search.includes('Attendance') && <Attendance role={'student'} />}
      {location.search.includes('Events') && <EventsTimetable role={'student'} />}
      {location.search.includes('Course') && <CourseDetails />}
      {location.search.includes('Lecture') && <LectureSummary role={'student'} />}
      {location.search.includes('Sessions') && <LiveSessions role={'student'} />}
      {location.search.includes('Assignment') && <Assignment role={'student'} />}
      {location.search.includes('Report') && <MarksResult role={'student'} />}
      {location.search.includes('Feedback') && <Feedback role="Student" />}
      {location.search.split('=')[1] === 'Fee' && <Fee role={'student'} />}
      {location.search.includes('parentAdmissionRes') && <NextAdmission role="student" />}
      {location.search.includes('HomeWork') && <HomeWork />}
    </div>
  );
}

export default index;
