import React, { useState, useEffect } from 'react';
import Add from '../../../components/Teacher/HomeWork/Add';
import { GET_UPLOADED_HOMEWORK, DELETE_HOMEWORK } from '../../../api/urls/notes';
import useApi from '../../../api/useApi';
import moment from 'moment';

function HomeWork({ subjects }) {
  const [selectedSubject, selectSubject] = useState(null);
  const [list, setList] = useState({});
  const [selectedHW, selectHW] = useState();

  const [homeworks, getHomeworks] = useApi();
  const [deleteRes, deleteHomework] = useApi();

  useEffect(() => {
    if (subjects.length > 0) {
      let groupedItems = {};
      subjects.forEach((subject) => {
        groupedItems[subject.subjectId + subject.section._id] = [];
      });
      setList(groupedItems);
    }
  }, [subjects]);

  useEffect(() => {
    getHomeworks(GET_UPLOADED_HOMEWORK, 'GET');
  }, []);

  useEffect(() => {
    if (
      !homeworks.isLoading &&
      !homeworks.isError &&
      homeworks.data &&
      Array.isArray(homeworks.data) &&
      subjects.length > 0
    ) {
      homeworks.data.forEach((homework) => {
        if (Array.isArray(list[homework.subjectId._id + homework.sectionId]))
          list[homework.subjectId._id + homework.sectionId].push(homework);
      });
      setList(list);
    }
  }, [homeworks, subjects]);

  const onClose = (refresh) => {
    if (refresh) {
      let groupedItems = {};
      subjects.forEach((subject) => {
        groupedItems[subject.subjectId + subject.section._id] = [];
      });
      setList(groupedItems);
      getHomeworks(GET_UPLOADED_HOMEWORK, 'GET');
    }
    selectSubject(null);
    selectHW(null);
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete?')) deleteHomework(DELETE_HOMEWORK(id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 200) {
      if (subjects.length > 0) {
        let groupedItems = {};
        subjects.forEach((subject) => {
          groupedItems[subject.subjectId + subject.section._id] = [];
        });
        setList(groupedItems);
      }
      getHomeworks(GET_UPLOADED_HOMEWORK, 'GET');
      alert('Success');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert(deleteRes?.data || 'Failed');
    }
  }, [deleteRes]);

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      {selectedSubject && <Add handleClose={onClose} classSubject={selectedSubject} selectedHW={selectedHW} />}

      {subjects.length > 0 ? (
        subjects &&
        subjects.map((y) => (
          <div className="w-full bg-white rounded-xl h-auto mb-5">
            <div className="items-center px-6 py-3 border-b border-borderYellow">
              <div className="lg:flex block sm:block justify-between items-center">
                <div className="font-bold w-4/12">
                  <p>{`${y.subjectName} (${y.class.name} - ${y.section.name})`}</p>
                </div>
                <div className=" w-full ml-2 flex justify-end">
                  <button
                    className="border px-6 py-2 rounded-3xl bg-primary text-xs  text-white  focus:outline-none flex justify-center text-center items-center"
                    onClick={() => selectSubject(y)}
                  >
                    Add Homework
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap w-full h-1/4">
              <div className="w-full py-4 overflow-auto">
                <div
                  className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
                  style={{ width: '96%' }}
                >
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-2/12 border-r-2 py-2 px-2">Date</div>
                      <div className="w-3/12 border-r-2 py-2 px-2">Title</div>
                      <div className="w-4/12 border-r-2 py-2 px-2">Summary</div>
                      {/* <div className="w-2/12 border-r-2 py-2 px-2">File</div> */}
                      <div className="w-3/12 py-2 px-2">Action</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '45vh' }}>
                    {homeworks.isLoading ? (
                      <img src="assets/img/loader.svg" className="w-14 m-auto" alt="" />
                    ) : list[y.subjectId + y.section._id] && list[y.subjectId + y.section._id].length > 0 ? (
                      list[y.subjectId + y.section._id].map((x, idx) => (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                              list[y.subjectId + y.section._id].length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/12 px-2 text-tableRowText">{moment(x?.date).format('ll')}</div>
                            <div className="w-3/12 px-2 text-tableRowText truncate">{x?.title}</div>
                            <div className="w-4/12 px-2 text-tableRowText truncate">{x?.summary}</div>
                            {/* <div className="w-2/12 px-2">
                              <a
                                className="border border-primary py-2 rounded-3xl text-primary w-9/12 font-medium focus:outline-none flex justify-center text-center items-center text-xs"
                                // href={x?.files[0].location}
                                download
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download
                              </a>
                            </div> */}
                            <div className="w-3/12 px-2 flex gap-x-3">
                              <button
                                className="border border-primary py-2 rounded-3xl text-primary w-1/2 font-medium focus:outline-none flex justify-center text-center items-center text-xs"
                                onClick={() => {
                                  selectHW(x);
                                  selectSubject(y);
                                }}
                              >
                                View
                              </button>
                              <button
                                className="border border-primary py-2 rounded-3xl text-primary w-1/2 font-medium focus:outline-none flex justify-center text-center items-center text-xs"
                                onClick={() => {
                                  handleDelete(x._id);
                                }}
                                disabled={deleteRes.isLoading}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">
                        No Data Available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-tableRowText text-xl font-light leading-6 my-8 text-center">No Data Available</p>
      )}
    </div>
  );
}

export default HomeWork;
