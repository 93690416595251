import React, { useState, useEffect } from 'react';
import moment from 'moment';

import DropDown from '../../UI/DropDown';
import ExcelExportBtn from '../../UI/ExcelExportBtn';

const excelHeaders = [
  { label: 'Date', key: 'date' },
  { label: 'Title', key: 'title' },
  { label: 'Description', key: 'desc' },
];

function Events({ selectEvent, events, isLoading }) {
  const [sort, setSort] = useState('');

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (events && Array.isArray(events)) {
      let x = events.map((x) => {
        return {
          date: moment(x.eventDate).format('D MMMM yyyy') || moment(x.start).format('D MMMM yyyy'),
          title: x?.title || '---',
          desc: x?.description || '---',
        };
      });

      if (Array.isArray(x)) setExcelData(x);
    }
  }, [events]);

  let sortedData = events || [];

  if (sort === 'Latest') {
    sortedData = sortedData.sort((a, b) => (moment(a.eventDate).isBefore(b.eventDate) ? 1 : -1));
  } else if (sort === 'Oldest') {
    sortedData = sortedData.sort((a, b) => (moment(a.eventDate).isAfter(b.eventDate) ? 1 : -1));
  }

  return (
    <div className="w-full bg-white rounded-xl h-auto my-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex flex-col md:flex-row justify-between md:items-center">
          <div className="font-bold w-1/3">
            <p>Events</p>
          </div>
          <div className="flex gap-x-4 w-full md:w-2/3 justify-end">
            <ExcelExportBtn data={excelData} headers={excelHeaders} fileName={'events.csv'} text={'Export Data'} />

            <DropDown
              data={['Latest', 'Oldest']}
              width={'w-1/2 md:w-1/4'}
              title={'Sort By'}
              handleItemClick={(x) => {
                setSort(x);
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4 px-6">
        <div className="w-full py-4 overflow-auto">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/5 border-r-2 py-2 px-2">Date</div>
                <div className="w-3/5 border-r-2 py-2 px-2">Description</div>
                <div className="w-1/5 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '55vh' }}>
              {Array.isArray(sortedData) &&
                sortedData.map((x, idx) => {
                  return (
                    <div className="w-full">
                      <div
                        className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                          sortedData.length - 1 !== idx && 'border-b border-borderYellow'
                        }`}
                      >
                        <div className="w-1/5 px-2 ">
                          {moment(x.eventDate).format('D MMMM yyyy') || moment(x.start).format('D MMMM yyyy')}
                        </div>
                        <div className="w-3/5 px-2 pr-6 text-textDark overflow-ellipsis overflow-hidden whitespace-nowrap">
                          {x?.description || '---'}
                        </div>
                        <div className={`w-1/5 px-2 text-textDark pr-6`}>
                          {' '}
                          <button
                            className="w-11/12 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-primary"
                            onClick={() => selectEvent(x)}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
