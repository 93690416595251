import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import { ASSIGNMENT_MARKS, UPLOAD_ASSIGNMENT_MARKS } from '../../../api/urls/notes';

function AssignmentMarks({ assignmentId, setTestAssignMent, setAssignmentId }) {
  const [assignmentMarks, setAssignmentMarks] = useState([]);

  const [assignmentMarksState, getAssignmentMarks] = useApi();
  const [uploadAssignmentState, uploadAssignmentMArks] = useApi();

  useEffect(() => {
    if (assignmentId) {
      setTestAssignMent(true);
      getAssignmentMarks(
        ASSIGNMENT_MARKS(assignmentId?.class_id, assignmentId?.section_id, assignmentId?.subject_id, assignmentId._id),
        'GET'
      );

      window.scrollTo(0, 0);
    }
  }, [assignmentId]);

  useEffect(() => {
    if (!assignmentMarksState.isLoading && !assignmentMarksState.isError && assignmentMarksState.data) {
      setAssignmentMarks(assignmentMarksState.data.assignment);
    }
  }, [assignmentMarksState]);

  const handleAssignmentMarksChange = (e, studentId) => {
    let studentIndex = assignmentMarks.findIndex((x) => x._id === studentId);
    let y = assignmentMarks[studentIndex];

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    let x = {
      ...y,
      marks: inputValue,
      status: true,
    };
    assignmentMarks[studentIndex] = x;
    setAssignmentMarks([...assignmentMarks]);
  };

  const handleSubmitAssignmentMarks = () => {
    if (assignmentMarksState?.data?.isMarked) {
      if (!window.confirm('Are you sure you want to edit marks?')) return;
    }
    let isMarksMissing = false;
    const marksBody = assignmentMarks.map((x) => {
      if (!x.marks) {
        isMarksMissing = true;
      }
      return {
        id: x._id,
        marks: +x.marks,
        status: x.status,
        assignment_id: x.assignment_id,
      };
    });

    if (isMarksMissing) {
      alert("Some students's marks are missing");
      return;
    }

    if (assignmentMarksState?.data?.isMarked)
      uploadAssignmentMArks(UPLOAD_ASSIGNMENT_MARKS(assignmentId._id), 'PUT', marksBody);
    else uploadAssignmentMArks(UPLOAD_ASSIGNMENT_MARKS(assignmentId._id), 'POST', marksBody);
  };

  useEffect(() => {
    if (
      !uploadAssignmentState.isLoading &&
      !uploadAssignmentState.isError &&
      [200, 201, 202].includes(uploadAssignmentState.status)
    ) {
      alert('Success');
      setTestAssignMent(false);
      setAssignmentId(null);
    }

    if (!uploadAssignmentState.isLoading && uploadAssignmentState.isError) {
      alert('Faled');
    }
  }, [uploadAssignmentState.isLoading, uploadAssignmentState.isError, uploadAssignmentState.data]);

  return (
    <div className="w-full bg-white rounded-xl h-auto mb-5 pb-5">
      <div className="items-center px-6 py-4 border-b border-borderYellow">
        <p
          className="text-xs text-tableRowText mb-3 cursor-pointer"
          onClick={() => {
            setTestAssignMent(false);
            setAssignmentId(null);
          }}
        >
          {`Test & Assignments > ${assignmentId?.subjectName}  (${assignmentId?.className} - ${assignmentId?.sectionName}) > ${assignmentId?.title}`}
        </p>
        <div className="flex justify-between items-center">
          <div className="font-bold w-5/12">
            <p>{assignmentId?.title}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full h-1/4">
        <div className="w-full py-4 overflow-auto">
          <div
            className="bg-white m-auto border rounded-2xl border-borderYellow overflow-hidden min-w-700"
            style={{ width: '96%' }}
          >
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-2/12 border-r-2 py-2 px-2">Roll Number</div>
                <div className="w-5/12 border-r-2 py-2 px-2">Student Name</div>
                <div className="w-2/12 border-r-2 py-2 px-2">Marks Obtained</div>
                <div className="w-3/12 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="customScrollBar" style={{ overflowY: 'scroll', maxHeight: '56vh' }}>
              {assignmentMarksState.isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
              ) : (
                assignmentMarks.map((x, idx) => (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-3 px-4 text-xs items-center ${
                        idx !== assignmentMarks.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-2/12 px-2 text-textDark">--</div>
                      <div className="w-5/12 px-2 text-textDark">{x.name}</div>
                      <div className="w-2/12 px-2">
                        <input
                          type="number"
                          className="w-20 px-4 text-xs rounded-3xl py-2 border border-tableRowText"
                          placeholder="00"
                          value={x.marks}
                          onChange={(e) => handleAssignmentMarksChange(e, x._id)}
                        />
                      </div>
                      <div className="w-3/12 px-2">
                        {x?.files && (
                          <a
                            className="border w-10/12 border-primary px-4 py-2 rounded-3xl text-primary font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                            // onClick={() => setCreateTask(true)}
                            href={x?.files?.files[0]?.location || ''}
                            download
                          >
                            View Assignment
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-x-4 mt-3 justify-end">
        {uploadAssignmentState.isLoading ? (
          <img src="assets/img/loader.svg" className="w-14 ml-auto mt-4 mr-4" alt="" />
        ) : (
          <>
            <button
              className="border border-borderLight w-32 ml-auto px-4 py-2 rounded-3xl text-xs focus:outline-none flex justify-center text-center items-center text-tableRowText"
              onClick={() => {
                setTestAssignMent(false);
                setAssignmentId(null);
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="border bg-primary w-32 mr-5 px-4 py-2 rounded-3xl text-xs focus:outline-none flex justify-center text-center items-center text-white"
              onClick={handleSubmitAssignmentMarks}
            >
              {assignmentMarksState?.data?.isMarked ? 'Edit' : 'Save'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default AssignmentMarks;
