import React from 'react';
// import { Configuration, OpenAIApi } from 'openai';
import axios from 'axios';

const TextExtraction = () => {
  const imgUpload = React.useRef();
  const [img, setImage] = React.useState();
  const [response, setResponse] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  // const openai = new OpenAI({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  // });
  // const openai = new OpenAIApi(
  //   new Configuration({
  //     apiKey: process.env.OPENAI_API_KEY,
  //   })
  // );

  const encodeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]); // Extract base64 string
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const extractText = async () => {
    setIsLoading(true);
    const base64Image = await encodeImage(img);
    axios
      .post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: 'Extract text from the image for me?',
                },
                {
                  type: 'image_url',
                  image_url: {
                    url: `data:image/jpeg;base64,${base64Image}`,
                  },
                },
              ],
            },
          ],
          max_tokens: 300,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer sk-proj-2GYEsmjjf7T48hED44WGT3BlbkFJbmWXUqIup8gjYP9xjieX',
          },
        }
      )
      .then((res) => setResponse(res?.data?.choices[0]?.message?.content || ''))
      .catch((err) => {
        console.log(err);
        alert('Some error has occured');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="relative w-full md:w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-5">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="lg:flex block sm:block justify-between items-center">
            <div className="font-bold w-6/12">
              <p>Text Extraction</p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full h-1/4 p-6">
          <div className="my-2 w-1/2 relative font-semibold">
            <label className="text-textDark font-medium text-sm">Upload Image</label>
            <div className="relative flex mt-2">
              <input
                hidden
                ref={imgUpload}
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
              <input
                // required
                type="text"
                disabled
                value={img?.name || ''}
                className="w-full rounded-3xl text-xs border border-borderLight px-4 py-2 focus:outline-none"
              />
              <button
                className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center absolute right-0 bottom-0 top-0"
                onClick={() => imgUpload?.current?.click()}
              >
                Select Image
              </button>
            </div>
          </div>

          <div className="flex flex-col mt-8 w-full gap-3">
            {img && <img className="h-64 w-auto mx-auto" src={URL.createObjectURL(img)}></img>}

            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <>
                <div className="flex gap-x-6 justify-end">
                  <button
                    className="w-36 py-2 rounded-3xl bg-white text-xs text-textLight border border-borderLight focus:outline-none flex justify-center text-center items-center mt-4"
                    onClick={() => {
                      setImage(null);
                      setResponse(null);
                    }}
                  >
                    Reset
                  </button>

                  <button
                    className="w-36 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center mt-4"
                    onClick={() => extractText()}
                  >
                    Extract Text
                  </button>
                </div>
              </>
            )}
            {response && (
              <div className="mt-6">
                <p className="font-semibold text-lg">Extracted Text</p>
                <p className="">{response}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextExtraction;
